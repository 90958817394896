<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="会员id" prop="memberId">
        <el-input
          v-model="queryParams.memberId"
          placeholder="请输入会员id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="被邀请会员id" prop="inviteMemberId">
        <el-input
          v-model="queryParams.inviteMemberId"
          placeholder="请输入被邀请会员id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="邀请金额" prop="inviteMoney">
        <el-input
          v-model="queryParams.inviteMoney"
          placeholder="请输入邀请金额"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="邀请金发放标识" prop="payFlag">
        <el-input
          v-model="queryParams.payFlag"
          placeholder="请输入邀请金发放标识"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="邀请金发放类型" prop="payType">
        <el-select v-model="queryParams.payType" placeholder="请选择邀请金发放类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['system:invite:add']"
        >
          新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['system:invite:edit']"
        >
          修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['system:invite:remove']"
        >
          删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:invite:export']"
        >
          导出
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="inviteList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="会员id" align="center" prop="memberId" />
      <el-table-column label="被邀请会员id" align="center" prop="inviteMemberId" />
      <el-table-column label="邀请金额" align="center" prop="inviteMoney" />
      <el-table-column label="邀请金发放标识(0:未发放;1:已发放)" align="center" prop="payFlag" />
      <el-table-column label="邀请金发放类型(1:支付宝;2:微信;3:银行卡)" align="center" prop="payType" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:invite:edit']"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:invite:remove']"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改会员邀请对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="会员id" prop="memberId">
          <el-input v-model="form.memberId" placeholder="请输入会员id" />
        </el-form-item>
        <el-form-item label="被邀请会员id" prop="inviteMemberId">
          <el-input v-model="form.inviteMemberId" placeholder="请输入被邀请会员id" />
        </el-form-item>
        <el-form-item label="邀请金额" prop="inviteMoney">
          <el-input v-model="form.inviteMoney" placeholder="请输入邀请金额" />
        </el-form-item>
        <el-form-item label="邀请金发放标识(0:未发放;1:已发放)" prop="payFlag">
          <el-input v-model="form.payFlag" placeholder="请输入邀请金发放标识(0:未发放;1:已发放)" />
        </el-form-item>
        <el-form-item label="邀请金发放类型(1:支付宝;2:微信;3:银行卡)">
          <el-select v-model="form.payType" placeholder="请选择邀请金发放类型(1:支付宝;2:微信;3:银行卡)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="删除标记(0:未删除;1:已删除)" prop="delFlag">
          <el-input v-model="form.delFlag" placeholder="请输入删除标记(0:未删除;1:已删除)" />
        </el-form-item>
        <el-form-item label="创建人" prop="createBy">
          <el-input v-model="form.createBy" placeholder="请输入创建人" />
        </el-form-item>
        <el-form-item label="修改人" prop="updateBy">
          <el-input v-model="form.updateBy" placeholder="请输入修改人" />
        </el-form-item>
        <el-form-item label="创建时间" prop="createTime">
          <el-date-picker clearable size="small" style="width: 200px"
                          v-model="form.createTime"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择创建时间" />
        </el-form-item>
        <el-form-item label="修改时间" prop="updateTime">
          <el-date-picker clearable size="small" style="width: 200px"
                          v-model="form.updateTime"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择修改时间" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listInvite, getInvite, delInvite, addInvite, updateInvite, exportInvite
} from '@/api/system/invite';

export default {
  name: 'Invite',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员邀请表格数据
      inviteList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        memberId: undefined,
        inviteMemberId: undefined,
        inviteMoney: undefined,
        payFlag: undefined,
        payType: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询会员邀请列表 */
    getList() {
      this.loading = true;
      listInvite(this.queryParams).then((response) => {
        response = response.data;
        this.inviteList = response.records;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        memberId: undefined,
        inviteMemberId: undefined,
        inviteMoney: undefined,
        payFlag: undefined,
        payType: undefined,
        delFlag: undefined,
        createBy: undefined,
        updateBy: undefined,
        createTime: undefined,
        updateTime: undefined
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '添加会员邀请';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getInvite(id).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = '修改会员邀请';
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            updateInvite(this.form).then((response) => {
              if (response.code === '200') {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addInvite(this.form).then((response) => {
              if (response.code === '200') {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm(`是否确认删除会员邀请编号为"${ids}"的数据项?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => delInvite(ids)).then(() => {
        this.getList();
        this.msgSuccess('删除成功');
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const { queryParams } = this;
      this.$confirm('是否确认导出所有会员邀请数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => exportInvite(queryParams)).then((response) => {
        this.download(response.data);
      }).catch(() => {});
    }
  }
};
</script>
