<template>
  <div class="all login-page">
    <div class="login-back">
      <img src="~@/assets/login/login-back.png" class="img-back">
      <div class="login-form">
        <div class="login-main">
          <div class="wrap">
            <div class="title">润海通后台管理系统</div>
            <div class="tips hide" id="errorMsg" />
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                     class="demo-ruleForm">
              <el-form-item label="用户名" prop="username">
                <el-input v-model="ruleForm.username" autocomplete="off" />
              </el-form-item>
              <el-form-item label="密码" prop="pwd">
                <el-input type="password" v-model="ruleForm.pwd" autocomplete="off" />
              </el-form-item>
            </el-form>
            <div class="submit" id="login" @click="submitForm('ruleForm')">
              登录
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { validUsername } from '@/utils/validate';
import { login } from '@/api/user';

export default {
  data() {
    return {
      ruleForm: {
        username: '',
        pwd: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          },
        ],
        pwd: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }
        ],
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const {
            username,
            pwd
          } = this.ruleForm;
          const captcha = '888888';
          login({
            username,
            password: pwd,
            captcha,
          })
            .then((res) => {
              this.$message.success('登录成功');
              localStorage.setItem('permList', _.join(res.data.permList, ','));
              localStorage.setItem('user', JSON.stringify(_.omit(res.data, 'permList')));
              this.$router.push('/dashboard');
            });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style lang="less">
.login-page {
  background: url("~@/assets/login/background.png") no-repeat center center / cover;
}

.all {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.all .login-back {
  position: relative;
  width: 90%;
}

.all .login-back .img-back {
  max-width: 100%;
}

.all .login-form {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.all .title {
  line-height: 30px;
  font-size: 26px;
  /* font-weight: bold; */
  text-align: center;
  color: #2196f3;
  margin-bottom: 50px;
}

.all .login-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 50%;
  float: right;
  padding: 60px 80px 100px 60px;
}

.all .group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 50px;
  margin-top: 20px;
}

.all .group .input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  border: 1px solid #ddd;
}

.all .group .label {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 40px;
  flex: 0 0 40px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% auto;
}

.all .group .label.account {
  background-image: url("~@/assets/login/account.png");
}

.all .group .label.password {
  background-image: url("~@/assets/login/password.png");
}

.all .group .label.verifyCode {
  background-image: url("~@/assets/login/verifyCode.png");
}

.all .group .value {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}

.all .group input {
  height: 100%;
  width: 100%;
  float: left;
}

.all .verifyImage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  margin: 0 10px;
}

.all .verifyImage img {
  max-height: 100%;
  max-width: 120px;
  /* float: left; */
}

.all .refresh {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #2196f3;
  font-size: 14px;
  cursor: pointer;
}

.all .submit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 46px;
  color: #fff;
  background-color: #2196f3;
  margin-top: 40px;
  cursor: pointer;
  border-radius: 6px;
}

.all .tips {
  display: -webkit-box;
  display: -ms-flexbox;
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  background-color: #dd4b39;
  border-radius: 6px;
}
</style>
