import request from '@/utils/request'

// 查询聊天记录列表
export function listChatlog(query) {
  return request({
    url: '/chatlog/list',
    method: 'get',
    params: query
  })
}

// 查询聊天记录详细
export function getChatlog(id) {
  return request({
    url: `/chatlog/info/${id}`,
    method: 'get'
  })
}

// 新增聊天记录
export function addChatlog(data) {
  return request({
    url: '/chatlog/save',
    method: 'post',
    data
  })
}

// 修改聊天记录
export function updateChatlog(data) {
  return request({
    url: '/chatlog/update',
    method: 'post',
    data
  })
}

// 删除聊天记录
export function delChatlog(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/chatlog/delete',
    method: 'post',
    data
  })
}

// 导出聊天记录
export function exportChatlog(query) {
  return request({
    url: '/chatlog/export',
    method: 'get',
    params: query
  })
}
