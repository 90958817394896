import request from '@/utils/request'

// 查询招聘职位列表
export function listPosition(query) {
  return request({
    url: '/position/list',
    method: 'get',
    params: query
  })
}

// 查询招聘职位详细
export function getPosition(id) {
  return request({
    url: `/position/info/${id}`,
    method: 'get'
  })
}

// 新增招聘职位
export function addPosition(data) {
  return request({
    url: '/position/save',
    method: 'post',
    data
  })
}

// 修改招聘职位
export function updatePosition(data) {
  return request({
    url: '/position/update',
    method: 'post',
    data
  })
}

// 删除招聘职位
export function delPosition(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/position/delete',
    method: 'post',
    data
  })
}

// 导出招聘职位
export function exportPosition(query) {
  return request({
    url: '/position/export',
    method: 'get',
    params: query
  })
}
