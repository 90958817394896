import request from '@/utils/request'

// 查询会员邀请列表
export function listInvite(query) {
  return request({
    url: '/invite/list',
    method: 'get',
    params: query
  })
}

// 查询会员邀请详细
export function getInvite(id) {
  return request({
    url: `/invite/info/${id}`,
    method: 'get'
  })
}

// 新增会员邀请
export function addInvite(data) {
  return request({
    url: '/invite/save',
    method: 'post',
    data
  })
}

// 修改会员邀请
export function updateInvite(data) {
  return request({
    url: '/invite/update',
    method: 'post',
    data
  })
}

// 删除会员邀请
export function delInvite(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/invite/delete',
    method: 'post',
    data
  })
}

// 导出会员邀请
export function exportInvite(query) {
  return request({
    url: '/invite/export',
    method: 'get',
    params: query
  })
}
