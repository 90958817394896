<template>
  <div class="app-container online-info-page">
    <el-form :model="queryParams" ref="queryForm" size="mini" :inline="true" label-width="75px">
      <el-form-item label="选择城市" prop="cityRegion">
        <el-select class="input-750" clearable filterable v-model="queryParams.cityRegion" placeholder="">
          <el-option v-for="item in cityList" :label="item.name" :value="item.key" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <!--        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>-->

        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column label="模板名称" align="center" prop="templateName" />
      <el-table-column label="城市" align="center" prop="cityName" />
      <el-table-column label="区域" align="center" prop="regionName" />
      <el-table-column label="户口类型" align="center" prop="nativeNames" />
      <el-table-column label="操作" align="center" width="180px" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleUpdate(scope.row)">
            编辑
          </el-button>

          <el-button size="mini" type="text" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page-sizes="[16, 30, 50, 80, 100]"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.limit"
      @pagination="getList"
    />
  </div>
</template>

<script>

import {
  insurancefundInsuranceList,
  insurancefundInsuranceSave,
  insurancefundInsuranceUpdate,
  insurancefundInsuranceDelete,
  insurancefundInsuranceInfo
} from '@/api/insurancefund';

import { listCityRegions, } from '@/api/comm';

export default {

  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        cityRegion: '',
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      cityList: [],

      // 表单参数
      form: {},
      // 表单校验
    };
  },
  created() {
    this.getList();
    this.getCityList();
  },
  methods: {
    getCityList() {
      listCityRegions()
        .then(({ data }) => {
          this.cityList = data;
        });
    },

    handleAvatarSuccess(res, file) {
      this.mainPhoto = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      const param = {
        ...queryParams,
        cityId: _.split(queryParams.cityRegion, ('-'))[0],
        regionId: _.split(queryParams.cityRegion, ('-'))[1],
      };
      insurancefundInsuranceList(param)
        .then((response) => {
          response = response.data;
          this.tableData = response.list;
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        diffFlag: 2,
        largeMedicalPayType: 1,
        insuranceEnterValidType: 1,
        fundEnterValidType: 1,
        insuranceLeaveValidType: 1,
        fundLeaveValidType: 1,
        insuranceChangeEnterValidType: 1,
        fundChangeEnterValidType: 1,
        insurancePayBackMonth: 0,
        fundPayBackMonth: 0
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push('/online/socialSecurity/socialSecurityTemplate/-1');
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push(`/online/socialSecurity/socialSecurityTemplate/${row.id}`);
    },
    enableItem(row) {
      insurancefundInsuranceUpdate({
        id: row.id,
        positionStatus: row.positionStatus === 1 ? 2 : 1,
      })
        .then((response) => {
          this.msgSuccess('修改成功');
          this.getList();
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        this.form.cityId = this.form.cityRegion.split('-')[0];
        this.form.regionId = this.form.cityRegion.split('-')[1];
        if (valid) {
          if (this.form.id != undefined) {
            insurancefundInsuranceUpdate(this.form)
              .then((response) => {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              });
          } else {
            insurancefundInsuranceSave(this.form)
              .then((response) => {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => insurancefundInsuranceDelete([ids]))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
  }
};
</script>

<style lang="less" scoped>
.online-info-page {

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

}

</style>
