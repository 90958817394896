<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="75px">
      <el-form-item label="订单号" prop="orderCode">
        <el-input
          v-model="queryParams.orderCode"
          placeholder="订单号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input
          v-model="queryParams.phone"
          placeholder="请输入手机号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column label="订单号" align="center" prop="orderCode" />
      <el-table-column label="月份" align="center" prop="payMonth" />
      <el-table-column label="手机号" align="center" prop="phone" />
      <el-table-column label="姓名" align="center" prop="empName" />
      <el-table-column label="身份证号" align="center" prop="idCard" />
      <el-table-column label="城市" align="center" prop="cityName" />
      <el-table-column label="状态" align="center" prop="orderStatusDes" />
      <el-table-column label="金额" align="center" prop="totalMoney" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:member:edit']"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:member:remove']"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page-sizes="[16, 30, 50, 80, 100]"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.limit"
      @pagination="getList"
    />

    <!-- 添加或修改会员对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="姓名" prop="userName">
          <el-input v-model="form.userName" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item label="登陆密码" prop="userPassword">
          <el-input v-model="form.userPassword" placeholder="请输入登陆密码" />
        </el-form-item>
        <el-form-item label="证件号" prop="icCard">
          <el-input v-model="form.icCard" placeholder="请输入证件号" />
        </el-form-item>
        <el-form-item label="企业员工标识(0:不是;1:是)" prop="empFlag">
          <el-input v-model="form.empFlag" placeholder="请输入企业员工标识(0:不是;1:是)" />
        </el-form-item>
        <el-form-item label="企业员工状态(1:未入职;2:已入职;3:已离职)">
          <el-radio-group v-model="form.memberStatus">
            <el-radio label="1">请选择字典生成</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="推荐人id" prop="fromMemberId">
          <el-input v-model="form.fromMemberId" placeholder="请输入推荐人id" />
        </el-form-item>
        <el-form-item label="删除标记(0:未删除;1:已删除)" prop="delFlag">
          <el-input v-model="form.delFlag" placeholder="请输入删除标记(0:未删除;1:已删除)" />
        </el-form-item>
        <el-form-item label="创建人" prop="createBy">
          <el-input v-model="form.createBy" placeholder="请输入创建人" />
        </el-form-item>
        <el-form-item label="修改人" prop="updateBy">
          <el-input v-model="form.updateBy" placeholder="请输入修改人" />
        </el-form-item>
        <el-form-item label="创建时间" prop="createTime">
          <el-date-picker clearable size="small" style="width: 200px"
                          v-model="form.createTime"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择创建时间" />
        </el-form-item>
        <el-form-item label="修改时间" prop="updateTime">
          <el-date-picker clearable size="small" style="width: 200px"
                          v-model="form.updateTime"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择修改时间" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listMember, getMember, delMember, addMember, updateMember, exportMember,
  memberOrderList,
} from '@/api/system/member';

export default {

  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询会员列表 */
    getList() {
      this.loading = true;
      memberOrderList(this.queryParams)
        .then((response) => {
          response = response.data;
          this.tableData = response.list;
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: '0',
        fromMemberId: undefined,
        delFlag: undefined,
        createBy: undefined,
        updateBy: undefined,
        createTime: undefined,
        updateTime: undefined
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '添加会员';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids;
      getMember(id)
        .then((response) => {
          this.form = response.data;
          this.open = true;
          this.title = '修改会员';
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            updateMember(this.form)
              .then((response) => {
                if (response.code === '200') {
                  this.msgSuccess('修改成功');
                  this.open = false;
                  this.getList();
                } else {
                  this.msgError(response.msg);
                }
              });
          } else {
            addMember(this.form)
              .then((response) => {
                if (response.code === '200') {
                  this.msgSuccess('新增成功');
                  this.open = false;
                  this.getList();
                } else {
                  this.msgError(response.msg);
                }
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => delMember(ids))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      const { queryParams } = this;
      this.$confirm('是否确认导出所有会员数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => exportMember(queryParams))
        .then((response) => {
          this.download(response.data);
        })
        .catch(() => {
        });
    }
  }
};
</script>
