import request from '@/utils/request'

// 查询服务介绍列表
export function listService(query) {
  return request({
    url: '/service/list',
    method: 'get',
    params: query
  })
}

// 查询服务介绍详细
export function getService(id) {
  return request({
    url: `/service/info/${id}`,
    method: 'get'
  })
}

// 新增服务介绍
export function addService(data) {
  return request({
    url: '/service/save',
    method: 'post',
    data
  })
}

// 修改服务介绍
export function updateService(data) {
  return request({
    url: '/service/update',
    method: 'post',
    data
  })
}

// 删除服务介绍
export function delService(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/service/delete',
    method: 'post',
    data
  })
}

// 导出服务介绍
export function exportService(query) {
  return request({
    url: '/service/export',
    method: 'get',
    params: query
  })
}
