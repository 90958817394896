<template>
  <div class="app-container online-info-page">
    <!--        <el-row :gutter="10" class="mb8">-->
    <!--          <el-col :span="1.5">-->
    <!--            <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">-->
    <!--              新增-->
    <!--            </el-button>-->
    <!--          </el-col>-->
    <!--        </el-row>-->

    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="100px">
      <el-form-item label="客户单位名称" prop="registryDate">
        <el-input placeholder="客户单位名称" v-model="queryParams.companyName"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" icon="el-icon-search" @click="handleQuery">搜索</el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="tableData">
      <!--      <el-table-column label="用户ID" align="center" prop="userId"/>-->
      <el-table-column label="客户单位名称" align="center" prop="companyName"/>
      <el-table-column label="所属分公司" align="center" prop="sysCompanyName"/>
      <el-table-column label="单位管理员账号" align="center" prop="username" width="150px"/>
      <el-table-column label="状态" align="center" width="70px">
        <template slot-scope="{row}">
          <el-tag type="danger" v-show="row.status === 0">禁用</el-tag>
          <el-tag type="success" v-show="row.status === 1">正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="200px">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleUpdate(scope.row)">
            配置账号
          </el-button>
          <el-button size="mini" type="text" @click="enableItem(scope.row)">
            {{ `${scope.row.status}` === '1' ? '禁用' : '启用' }}
          </el-button>
          <!--          <el-button size="mini" type="text" @click="handleDelete(scope.row)">-->
          <!--            删除-->
          <!--          </el-button>-->
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page-sizes="[16, 30, 50, 80, 100]"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.limit"
      @pagination="getList"
    />

    <!-- 添加或修改会员对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="750px">
      <el-form ref="form" :model="form" :rules="rules" label-width="90px">

        <el-form-item label="分公司" prop="sysCompanyName">
          <el-input v-model="form.sysCompanyName" disabled placeholder="分公司"/>
        </el-form-item>

        <el-form-item label="客户单位" prop="companyName">
          <el-input v-model="form.companyName" disabled placeholder="客户单位"/>
        </el-form-item>

        <el-form-item label="管理员账号" prop="username">
          <el-input v-model="form.username" placeholder="管理员账号"/>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="form.password" placeholder="密码"/>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  companyUserList, companyUserUpdateStatus, companyUserSetAccount
// eslint-disable-next-line import/extensions
} from '@/api/system.js';
import { getRoleList } from '@/api/comm';

export default {

  data() {
    return {
      roleList: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        companyName: '',
        page: 1,
        limit: 16,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {}
    };
  },
  created() {
    this.getList();
    // this.getRoleList();
  },
  methods: {
    getRoleList() {
      getRoleList()
        .then((res) => {
          console.log(res);
          this.roleList = res.list;
        });
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      companyUserList(queryParams)
        .then((response) => {
          response = response.data;
          this.tableData = response.list;
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        username: '',
        password: '',
        email: '',
        mobile: '',
        roleIdList: [],
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增信息';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      // const id = row.userId;
      this.open = true;
      this.$nextTick(() => {
        this.form = {
          ...row,
          password: '',
        }
      })
      // sysUserInfoId(id)
      //   .then((response) => {
      //     this.form = response.user;
      //     this.open = true;
      //     this.title = '修改信息';
      //   });
    },
    enableItem(row) {
      companyUserUpdateStatus({
        comId: row.comId,
        status: row.status === 1 ? 0 : 1,
      })
        .then((response) => {
          this.msgSuccess('修改成功');
          this.getList();
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = this.form;
          // if (this.form.userId) {
          companyUserSetAccount(_.pick(params, ['comId', 'password', 'username']))
            .then((response) => {
              this.msgSuccess('修改成功');
              this.open = false;
              this.getList();
            });
          // } else {
          //   sysUserSave(params)
          //     .then((response) => {
          //       this.msgSuccess('新增成功');
          //       this.open = false;
          //       this.getList();
          //     });
          // }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.userId;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => sysUserDelete([ids]))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
  }
};
</script>
