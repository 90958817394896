<template>
  <div class="app-container">
    <el-table v-loading="loading" :data="tableData">
      <el-table-column label="姓名" align="center" prop="userName"/>
      <el-table-column label="手机号" align="center" prop="phone"/>
      <el-table-column label="提现金额" align="center" prop="money"/>
      <el-table-column label="申请提现时间" align="center" prop="createTime"/>
      <el-table-column label="审核状态" align="center" prop="rewardStatus">
        <template slot-scope="scope">
          {{ rewardStatus[scope.row.rewardStatus] }}
        </template>
      </el-table-column>
      <!--      <el-table-column label="企业员工状态(1:未入职;2:已入职;3:已离职)" align="center" prop="memberStatus"/>-->
      <el-table-column label="审核时间" align="center" prop="auditTime"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">

          <el-button
            v-if="form.rewardStatus === 2"
            size="mini"
            type="text"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['client:client:update']"
          >
            审核
          </el-button>

          <el-button
            v-else
            size="mini"
            type="text"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['client:client:update']"
          >
            详情
          </el-button>


        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page-sizes="[16, 30, 50, 80, 100]"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.limit"
      @pagination="getList"
    />

    <!-- 添加或修改会员对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="状态" v-show="form.rewardStatus !== 2">
          <label>审核通过</label>
        </el-form-item>
        <el-form-item label="审核原因" prop="userName">
          <el-input type="textarea" v-model="form.remark" :disabled="form.rewardStatus !== 2"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <template v-if="form.rewardStatus === 2">
          <el-button @click="check(4)">审核不通过</el-button>
          <el-button type="primary" @click="check(3)">审核通过</el-button>
        </template>

      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  memberRewardList, memberRewardAudit
} from '@/api/referralBonuses';

export default {
  data() {
    return {
      rewardStatus: {
        2: '审核中',
        3: '已到账',
        4: '驳回',
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        registryDate: [],
      },
      // 表单参数
      form: {
        auditTime: '',
        createTime: '',
        id: '',
        memberId: '',
        money: '',
        phone: '',
        rewardStatus: '',
        userName: '',
      },
      // 表单校验
      rules: {}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询会员列表 */
    getList() {
      this.loading = true;

      const { queryParams } = this;

      memberRewardList(queryParams)
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        auditTime: '',
        createTime: '',
        id: '',
        memberId: '',
        money: '',
        phone: '',
        rewardStatus: '',
        userName: '',
      };
      this.resetForm('form');
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.open = true;
      this.form = row;
      if (row.rewardStatus === 2) {
        this.title = '操作';
      } else {
        this.title = '详情';
      }
    },
    /** 提交按钮 */
    check(rewardStatus) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            id: this.form.id,
            remark: this.form.remark,
            rewardStatus,
          };
          memberRewardAudit(params)
            .then((response) => {
              if (response.code === '200') {
                this.msgSuccess('操作成功');
                this.cancel();
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      debugger
      const ids = row.id;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => delMember(ids))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      const { queryParams } = this;
      this.$confirm('是否确认导出所有会员数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => exportMember(queryParams))
        .then((response) => {
          this.download(response.data);
        })
        .catch(() => {
        });
    }
  }
};
</script>
