<template>
  <div class="app-container online-info-page">
    <div class="change-pwd-main">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="旧密码" prop="oldPwd">
          <el-input v-model="form.oldPwd" type="password" placeholder="请输入旧密码" />
        </el-form-item>
        <el-form-item label="新密码" prop="newPwd1">
          <el-input v-model="form.newPwd1" type="password" placeholder="请输入新密码" />
        </el-form-item>
        <el-form-item label="确认密码" prop="newPwd2">
          <el-input v-model="form.newPwd2" type="password" placeholder="请再次输入密码" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submit">确认修改</el-button>
          <el-button @click="cancel">取消修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { changePwd } from '@/api/user';

export default {
  data() {
    return {
      form: {
        oldPwd: '',
        newPwd1: '',
        newPwd2: '',
      },
      rules: {
        oldPwd: [
          {
            required: true,
            message: '旧密码不能为空',
            trigger: 'blur',
          },
        ],
        newPwd1: [
          {
            required: true,
            message: '新密码不能为空',
            trigger: 'blur',
          },
        ],
        newPwd2: [
          {
            required: true,
            message: '请确认两次密码一致',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;

        const { oldPwd, newPwd1, newPwd2 } = this.form;
        if (newPwd1 !== newPwd2) {
          this.$message.error('两次密码不一致');
          return;
        }

        changePwd(oldPwd, newPwd1).then((res) => {
          this.$message.success('修改成功');
          localStorage.clear();
          window.location.href = '/login';
        });
      });
    },
    cancel() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.change-pwd-main {
  width: 600px;
  // margin: 0 auto;
}
</style>
