<template>
  <div class="app-container online-info-page">
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column label="角色ID" align="center" prop="roleId" />
      <el-table-column label="角色名称" align="center" prop="roleName" />
      <el-table-column label="备注" align="center" prop="remark" />
      <el-table-column label="创建时间" align="center" prop="createTime" />

      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleUpdate(scope.row)">
            编辑
          </el-button>

          <!--<el-button size="mini" type="text" @click="enableItem(scope.row)">-->
            <!--{{ `${scope.row.dataStatus}` === '1' ? '禁用' : '启用' }}-->
          <!--</el-button>-->
          <el-button size="mini" type="text" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page-sizes="[16, 30, 50, 80, 100]"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.limit"
      @pagination="getList"
    />

    <!-- 添加或修改会员对话框 -->
    <el-dialog :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="open"
               width="1050px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="form.roleName" placeholder="用户名" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="备注" />
        </el-form-item>
        <el-form-item label="功能权限">
          <el-tree :props="{label: 'name',children: 'children'}"
                   show-checkbox
                   ref="menuTree"
                   node-key="menuId"
                   :data="menuList"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  sysRoleList, sysRoleSave, sysRoleUpdate, sysRoleInfo, sysRoleDelete
} from '@/api/system.js';
import { getMenuList } from '@/api/comm';

export default {

  data() {
    return {
      menuList: [],

      roleList: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      // 表单参数
      form: {
        title: '',
        mainPhoto: '',
        context: '',
      },
      // 表单校验
      rules: {}
    };
  },
  created() {
    this.getList();
    this.getMenuList();
  },
  methods: {
    getMenuList() {
      getMenuList()
        .then((res) => {
          console.log(123, res);
          this.menuList = this.handleMenuList(res.data);
        });
    },
    handleMenuList(data) {
      const map = {};
      _.each(data, (x) => {
        if (x.parentId === 0) {
          map[x.menuId] = {
            ...x,
            children: [],
          };
        }
      });
      _.each(data, (x) => {
        if (x.parentId !== 0) {
          if (map[x.parentId]) {
            map[x.parentId].children.push(x);
          }
        }
      });

      const result = [];
      _.each(map, (item) => {
        result.push(item);
      });
      return result;
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      sysRoleList(queryParams)
        .then((response) => {
          response = response.data;
          this.tableData = response.list;
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        deptId: null,
        deptName: null,
        menuIdList: [],
        remark: '',
        roleName: '',
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.roleId;
      sysRoleInfo(id)
        .then((response) => {
          this.form = response.role;
          // menuIdList
          this.open = true;
          setTimeout(() => {
            this.$refs.menuTree.setCheckedKeys(response.role.menuIdList);
          }, 500);
          this.title = '修改';
        });
    },
    enableItem(row) {
      sysRoleUpdate({
        id: row.id,
        dataStatus: row.dataStatus === 1 ? 2 : 1,
      })
        .then((response) => {
          this.msgSuccess('修改成功');
          this.getList();
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const menuIdList = this.$refs.menuTree.getCheckedKeys();
          const params = {
            ...this.form,
            menuIdList,
          };
          if (this.form.roleId) {
            sysRoleUpdate(params)
              .then((response) => {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              });
          } else {
            sysRoleSave(params)
              .then((response) => {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = [row.roleId];
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => sysRoleDelete(ids))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
  }
};
</script>
