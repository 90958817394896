import request from '@/utils/request'

// 查询好友管理列表
export function listRelation(query) {
  return request({
    url: '/relation/list',
    method: 'get',
    params: query
  })
}

// 查询好友管理详细
export function getRelation(id) {
  return request({
    url: `/relation/info/${id}`,
    method: 'get'
  })
}

// 新增好友管理
export function addRelation(data) {
  return request({
    url: '/relation/save',
    method: 'post',
    data
  })
}

// 修改好友管理
export function updateRelation(data) {
  return request({
    url: '/relation/update',
    method: 'post',
    data
  })
}

// 删除好友管理
export function delRelation(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/relation/delete',
    method: 'post',
    data
  })
}

// 导出好友管理
export function exportRelation(query) {
  return request({
    url: '/relation/export',
    method: 'get',
    params: query
  })
}
