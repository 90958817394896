<template>
  <div class="app-container offline-manager-page">
    <!--    <el-row :gutter="10" class="mb8">-->
    <!--      <el-col :span="1.5">-->
    <!--        <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"> 新增</el-button>-->
    <!--      </el-col>-->
    <!--    </el-row>-->

    <el-row>
      <el-col :span="5">
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAddCompany">新增公司</el-button>
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAddDept">新增部门</el-button>
        <div style="margin:15px 0;" />

        <el-tree ref="elTree" :data="companyList" node-key="tid" @node-click="handleTreeClick" highlight-current
                 :expand-on-click-node="false" default-expand-all :props="{ children: 'child', label: 'name' }">
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ data.name }}</span>
            <span>
              <el-button type="text" size="mini" icon="el-icon-edit"
                         @click.stop="() => handleTreeEdit(node, data)" />
              <el-button type="text" size="mini" icon="el-icon-delete"
                         @click.stop="() => handleTreeDelete(node, data)" />
            </span>
          </div>
        </el-tree>
      </el-col>

      <el-col :span="18" :offset="1">
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd2">新增管理员</el-button>
        <div style="margin:15px 0;" />
        <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
          <el-table-column label="姓名" align="center" prop="username" />
          <el-table-column label="手机号" align="center" prop="mobile" />
          <el-table-column label="所属公司" align="center" prop="companyName" />
          <el-table-column label="部门" align="center" prop="deptName" />
          <el-table-column label="角色" align="center" prop="roleName" />
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="handleUpdate(scope.row)">
                编辑
              </el-button>

              <el-button size="mini" type="text" @click="handleDelete(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="total>0"
          :total="total"
          :page-sizes="[16, 30, 50, 80, 100]"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>

    <!-- 新增用户   -->
    <el-dialog :title="title2" :visible.sync="open2" width="750px">
      <el-form ref="form2" :model="form2" :rules="rules2" label-width="85px">
        <el-form-item label="用户名" prop="username">
          <el-input class="w100" v-model="form2.username" placeholder="用户名" />
        </el-form-item>

        <el-form-item label="公司" prop="comId">
          <el-select @change="companyChange" class="w100" v-model="form2.comId" placeholder="先选择公司后选择部门">
            <el-option v-for="item in companyList" :label="item.name" :value="item.comId" />
          </el-select>
        </el-form-item>

        <el-form-item label="部门" prop="deptId">
          <el-select class="w100" v-model="form2.deptId" placeholder="先选择公司后选择部门">
            <el-option v-for="item in deptList" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item label="登录手机号" prop="mobile">
          <el-input class="w100" v-model="form2.mobile" placeholder="手机号" />
        </el-form-item>

        <el-form-item label="密码" prop="password">
          <el-input class="w100" v-model="form2.password" placeholder="密码" />
        </el-form-item>

        <el-form-item label="邮箱" prop="email">
          <el-input class="w100" v-model="form2.email" placeholder="邮箱" />
        </el-form-item>

        <el-form-item label="角色" prop="roleIdList">
          <el-checkbox-group :max="1" v-model="form2.roleIdList">
            <el-checkbox v-for="item in roleList" :label="item.roleId">{{ item.roleName }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm2">确 定</el-button>
        <el-button @click="cancel2">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 新增编辑部门，公司 -->
    <el-dialog :title="title" :visible.sync="open" width="750px">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px">
        <!--   新增公司     -->
        <template v-if="form.flag === 1">
          <el-form-item label="公司名称" prop="companyName">
            <el-input class="w100" v-model="form.companyName" placeholder="公司名称" />
          </el-form-item>
        </template>

        <!--   新增部门     -->
        <template v-else>
          <el-form-item label="部门名称" prop="deptName">
            <el-input class="w100" v-model="form.deptName" placeholder="部门名称" />
          </el-form-item>
        </template>

        <!--        <template v-show="!form.comId">-->
        <!--          <el-form-item label="公司名称" prop="companyName">-->
        <!--            <el-input class="w100" v-model="form.companyName" placeholder="公司名称"></el-input>-->
        <!--          </el-form-item>-->
        <!--        </template>-->

        <!--        &lt;!&ndash;    新增编辑部门    &ndash;&gt;-->
        <!--        <template v-show="form.deptName">-->
        <!--          <el-form-item label="部门名称" prop="deptName">-->
        <!--            <el-input class="w100" v-model="form.deptName" placeholder="部门名称"></el-input>-->
        <!--          </el-form-item>-->
        <!--        </template>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { v4 as uuidv4 } from 'uuid';

import _ from 'lodash';
import {
  sysCompanyUserList,
  sysCompanyUserSave,
  sysCompanyUserUpdate,
  sysCompanyUserInfo,
  sysCompanyUserDelete,
  sysCompanyGetTree,

  sysCompanyCompanyDelete,
  sysCompanyCompanySave,
  sysCompanyCompanyUpdate,
  sysCompanyDeptDelete,
  sysCompanyDeptSave,
  sysCompanyDeptUpdate,
  sysCompanyRoleSelect

} from '@/api/offline';

import { getCityTree, getRoleList } from '@/api/comm';

export default {

  data() {
    return {
      open2: false,
      title2: '',
      form2: {},

      roleList: [],

      companyList: [],
      cityList: [],
      cityMap: {},
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        comId: null,
        deptId: null,
        parentId: null,
        companyName: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        companyName: [
          {
            required: true,
            message: '公司名称不能为空',
            trigger: 'blur'
          }
        ],
        deptName: [
          {
            required: true,
            message: '部门名称不能为空',
            trigger: 'blur'
          }
        ],
      },
      rules2: {},
    };
  },
  computed: {
    deptList() {
      const { comId } = this.form2;
      const company = _.find(this.companyList, (x) => x.comId === comId);
      if (company) {
        return company.deptList;
      }
      return [];
    },
  },
  created() {
    // this.getList();
    // this.getCityList();
    this.getCompanyList();
    this.getRoleList();
  },
  methods: {
    getRoleList() {
      sysCompanyRoleSelect()
        .then((res) => {
          this.roleList = res.data;
        });
    },

    submitForm2() {
      this.$refs.form2.validate((valid) => {
        if (valid) {
          const data2 = {
            ...this.form2,
            roleId: this.form2.roleIdList[0],
          };
          console.log(data2, this.form2);
          if (data2.userId) {
            sysCompanyUserUpdate(data2)
              .then((res) => {
                this.getList();
                this.open2 = false;
                this.msgSuccess('操作成功');
              });
          } else {
            sysCompanyUserSave(data2)
              .then((res) => {
                this.getList();
                this.open2 = false;
                this.msgSuccess('操作成功');
              });
          }
        }
      });
    },
    cancel2() {
      this.open2 = false;
    },
    // 新增管理员
    handleAdd2() {
      this.open2 = true;
      this.form2 = {
        comId: '',
        deptId: '',
        email: '',
        mobile: '',
        password: '',
        username: '',
        roleIdList: [],
      };
      this.resetForm('form2');
    },
    companyChange() {
      this.form2.deptId = '';
    },

    // tree 点击删除
    handleTreeDelete(node, item) {
      const {
        comId,
        id: deptId,
        parentId,
        companyName,
      } = item;
      const { level } = node;
      if (level === 1) {
        // 删除公司
        this.$confirm('删除后该公司下的员工也将被删除', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => sysCompanyCompanyDelete([comId]))
          .then(() => {
            this.getCompanyList();
            this.msgSuccess('删除成功');
          })
          .catch(() => {
          });
      } else {
        // 删除部门
        this.$confirm('删除后该部门下的员工也将被删除', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => sysCompanyDeptDelete([deptId]))
          .then(() => {
            this.getCompanyList();
            this.msgSuccess('删除成功');
          })
          .catch(() => {
          });
      }
    },
    // tree 编辑编辑
    handleTreeEdit(node, item) {
      const {
        comId,
        id: deptId,
        parentId,
        companyName,
        deptName,
      } = item;
      const { level } = node;
      if (level === 1) {
        this.title = '编辑公司';
        this.form = {
          companyName,
          flag: 1,
          comId,
        };
      } else {
        this.title = '编辑部门';
        this.form = {
          comId,
          companyName,
          deptName,
          deptId,
          parentId,
          flag: 2,
        };
      }

      this.open = true;
      this.resetForm('form');
    },

    // 新增公司
    handleAddCompany() {
      this.open = true;
      this.title = '新增公司';
      this.form = {
        companyName: '',
        flag: 1,
      };
      this.resetForm('form');
    },

    // 新增部门
    handleAddDept() {
      this.open = true;
      this.title = '新增部门';
      this.form = {
        comId: this.queryParams.comId,
        companyName: this.queryParams.companyName,
        deptName: '',
        parentId: this.queryParams.parentId,
        flag: 2,
      };
      this.resetForm('form');
    },

    // 点击tree
    handleTreeClick(item, node) {
      console.log(item, node);
      const {
        comId,
        id: deptId,
        parentId,
        companyName,
      } = item;
      const { level } = node;

      this.queryParams.companyName = companyName || null;

      if (level === 1) {
        this.queryParams.comId = comId;
        this.queryParams.deptId = null;
        this.queryParams.parentId = '0';
      } else {
        this.queryParams.comId = comId;
        this.queryParams.parentId = deptId;
        this.queryParams.deptId = deptId;
      }
      this.getList();
    },
    // 获取城市列表
    getCityList() {
      getCityTree()
        .then(({ data }) => {
          this.cityList = this.handleCityTreeData(data);
        });
    },
    // 处理城市tre数据
    handleCityTreeData(data) {
      _.each(data, (x) => {
        this.cityMap[x.id] = x;
        if (x.children.length) {
          x.children = this.handleCityTreeData(x.children);
        } else {
          delete x.children;
        }
      });
      // return this.handleCityTreeData
      return data;
    },
    // 获取公司tree数据
    getCompanyList() {
      sysCompanyGetTree()
        .then(({ data }) => {
          this.companyList = this.handleCompanyTreeData(data);
          const firstData = this.companyList[0];
          setTimeout(() => {
            this.$refs.elTree.setCurrentKey(firstData.tid);
            this.queryParams.comId = firstData.comId;
            this.queryParams.companyName = firstData.companyName || null;
            this.getList();
          }, 800);
        });
    },
    // 处理公司tree数据
    handleCompanyTreeData(data) {
      _.each(data, (item) => {
        _.set(item, 'name', item.companyName || item.deptName);
        _.set(item, 'tid', uuidv4());
        // this.companyMap[item.tid] = item;
        if (_.size(item.deptList || item.children)) {
          _.set(item, 'child', this.handleCompanyTreeData(item.deptList || item.children));
        }
      });
      return data;
    },
    // 显示 cityName 的方法
    getCityName(list) {
      let result = '';
      _.each(list, (x, idx) => {
        if (idx > 0) {
          result += ',';
        }
        result += x.cityName;
      });
      return result;
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      sysCompanyUserList(queryParams)
        .then((response) => {
          response = response.data;
          this.tableData = response.list;
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        parentId: '',
        companyName: '',
        comId: '',
        deptId: '',
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.userId;
      sysCompanyUserInfo(id)
        .then((res) => {
          this.form2 = {
            ...res.data,
            comId: res.data.comId ? `${res.data.comId}` : '',
            deptId: res.data.deptId ? `${res.data.deptId}` : '',
            roleIdList: res.data.roleId ? [res.data.roleId] : [],
          };
          this.open2 = true;
          this.title2 = '修改';
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.flag === 1) {
            // 公司
            const param = {
              companyName: this.form.companyName,
            };

            if (this.form.comId) {
              param.id = this.form.comId;
              sysCompanyCompanyUpdate(param)
                .then(() => {
                  this.msgSuccess('操作成功');
                  this.open = false;
                  this.getCompanyList();
                });
            } else {
              sysCompanyCompanySave(param)
                .then(() => {
                  this.msgSuccess('操作成功');
                  this.open = false;
                  this.getCompanyList();
                });
            }
          } else {
            // 部门
            const param = _.pick(this.form, ['comId', 'deptName', 'parentId']);

            if (this.form.deptId) {
              param.id = this.form.deptId;
              sysCompanyDeptUpdate(param)
                .then(() => {
                  this.msgSuccess('操作成功');
                  this.open = false;
                  this.getCompanyList();
                });
            } else {
              sysCompanyDeptSave(param)
                .then(() => {
                  this.msgSuccess('操作成功');
                  this.open = false;
                  this.getCompanyList();
                });
            }
          }

          // if (this.form.comId != undefined) {
          //   sysCompanyUserUpdate(data)
          //     .then((response) => {
          //       this.msgSuccess('修改成功');
          //       this.open = false;
          //       this.getList();
          //     });
          // } else {
          //   sysCompanyUserSave(data)
          //     .then((response) => {
          //       this.msgSuccess('新增成功');
          //       this.open = false;
          //       this.getList();
          //     });
          // }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.userId;
      this.$confirm('删除后，该模板下的城市将不能正常进行费用缴纳', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => sysCompanyUserDelete([ids]))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
  }
};
</script>

<style lang="less" scoped>
.offline-manager-page {
  ///deep/ .el-tree-node__content{
  //  min-height: ;
  //}

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    min-height: 20px;
  }

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

}

</style>
