import request from '@/utils/request'

// 查询分组管理列表
export function listGroup(query) {
  return request({
    url: '/group/list',
    method: 'get',
    params: query
  })
}

// 查询分组管理详细
export function getGroup(id) {
  return request({
    url: `/group/info/${id}`,
    method: 'get'
  })
}

// 新增分组管理
export function addGroup(data) {
  return request({
    url: '/group/save',
    method: 'post',
    data
  })
}

// 修改分组管理
export function updateGroup(data) {
  return request({
    url: '/group/update',
    method: 'post',
    data
  })
}

// 删除分组管理
export function delGroup(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/group/delete',
    method: 'post',
    data
  })
}

// 导出分组管理
export function exportGroup(query) {
  return request({
    url: '/group/export',
    method: 'get',
    params: query
  })
}
