import request from '@/utils/request'

// 查询工作流信息列表
export function listWorkflow(query) {
  return request({
    url: '/workflow/list',
    method: 'get',
    params: query
  })
}

// 查询工作流信息详细
export function getWorkflow(id) {
  return request({
    url: `/workflow/info/${id}`,
    method: 'get'
  })
}

// 新增工作流信息
export function addWorkflow(data) {
  return request({
    url: '/workflow/save',
    method: 'post',
    data
  })
}

// 修改工作流信息
export function updateWorkflow(data) {
  return request({
    url: '/workflow/update',
    method: 'post',
    data
  })
}

// 删除工作流信息
export function delWorkflow(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/workflow/delete',
    method: 'post',
    data
  })
}

// 导出工作流信息
export function exportWorkflow(query) {
  return request({
    url: '/workflow/export',
    method: 'get',
    params: query
  })
}
