import request from '@/utils/request'

// 查询合作公司列表
export function listPartner(query) {
  return request({
    url: '/partner/list',
    method: 'get',
    params: query
  })
}

// 查询合作公司详细
export function getPartner(id) {
  return request({
    url: `/partner/info/${id}`,
    method: 'get'
  })
}

// 新增合作公司
export function addPartner(data) {
  return request({
    url: '/partner/save',
    method: 'post',
    data
  })
}

// 修改合作公司
export function updatePartner(data) {
  return request({
    url: '/partner/update',
    method: 'post',
    data
  })
}

// 删除合作公司
export function delPartner(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/partner/delete',
    method: 'post',
    data
  })
}

// 导出合作公司
export function exportPartner(query) {
  return request({
    url: '/partner/export',
    method: 'get',
    params: query
  })
}
