<template>
  <div class="app-container online-info-page">
    <el-form :model="queryParams" ref="queryForm" size="mini" :inline="true" label-width="75px">
      <el-form-item label="选择城市" prop="cityRegion">
        <el-select class="input-750" clearable filterable v-model="queryParams.cityRegion" placeholder="">
          <el-option v-for="item in cityList" :label="item.name" :value="item.key" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <!--        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>-->

        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>

    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column label="模板名称" align="center" prop="templateName" />
      <el-table-column label="城市" align="center" prop="cityName" />
      <el-table-column label="区域" align="center" prop="regionName" />
      <el-table-column label="户口类型" width="400px" align="center" prop="nativeNames" />
      <el-table-column label="基数最小值" align="center" prop="baseMin" />
      <el-table-column label="基数最大值" align="center" prop="baseMax" />
      <el-table-column label="操作" align="center" width="180px" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleUpdate(scope.row)">
            编辑
          </el-button>

          <el-button size="mini" type="text" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page-sizes="[16, 30, 50, 80, 100]"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.limit"
      @pagination="getList"
    />

    <!-- 添加或修改会员对话框 -->
    <el-dialog :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="open"
               width="1050px">
      <el-form ref="form" size="small" :model="form" :rules="rules" label-width="95px">
        <el-form-item label="模板名称" prop="templateName">
          <el-input v-model="form.templateName" placeholder="模板名称" />
        </el-form-item>

        <el-form-item label="城市区域" prop="cityRegion">
          <el-select class="w100" v-model="form.cityRegion" placeholder="城市区域">
            <el-option v-for="item in cityList" :label="item.name" :value="item.key" />
          </el-select>
        </el-form-item>

        <el-form-item label="户口类型" prop="nativeIds">
          <el-select class="w100" multiple v-model="form.nativeIds" placeholder="户口类型">
            <el-option v-for="item in nativeList" :label="item.name" :value="`${item.id}`" />
          </el-select>
        </el-form-item>

        <el-form-item label="基数最小值" prop="baseMin">
          <el-input v-model="form.baseMin" placeholder="基数最小值" />
        </el-form-item>

        <el-form-item label="基数最大值" prop="baseMax">
          <el-input v-model="form.baseMax" placeholder="基数最大值" />
        </el-form-item>

        <el-form-item label="公积金个人" prop="selfHousingFund">
          <el-input v-model="form.selfHousingFund" placeholder="基数最大值" />
        </el-form-item>

        <el-form-item label="公积金公司" prop="companyHousingFund">
          <el-input v-model="form.companyHousingFund" placeholder="基数最大值" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {
  insurancefundFundList,
  insurancefundFundSave,
  insurancefundFundUpdate,
  insurancefundFundDelete,
  insurancefundFundInfo
} from '@/api/insurancefund';

import { listCityRegions, getNativeList } from '@/api/comm';

import { uploadFile, } from '@/api/tool/file';
import _ from 'lodash';

export default {

  data() {
    return {
      nativeList: [],
      cityList: [],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      // 表单参数
      form: {
        uploadImg:'',
        templateName: '',
        cityRegion: '',
        nativeIds: [],
        baseMin: '',
        baseMax: '',
        selfHousingFund: '',
        companyHousingFund: ''
      },
      // 表单校验
      rules: {}
    };
  },
  created() {
    this.getList();
    this.getCityList();
    this.getNativeList();
  },
  methods: {
    getNativeList() {
      getNativeList()
        .then(({ data }) => {
          this.nativeList = data;
        });
    },
    getCityList() {
      listCityRegions()
        .then(({ data }) => {
          this.cityList = data;
        });
    },

    onEditorReady(editor) { // 准备编辑器

    },
    onEditorBlur() {
    }, // 失去焦点事件
    onEditorFocus() {
    }, // 获得焦点事件
    onEditorChange() {
    }, // 内容改变事件

    fileUpload(file, fileList) {
      console.log(file, fileList);
      const data = new FormData();
      data.append('file', file.raw);
      uploadFile(data)
        .then((res) => {
          // console.log(res);
          this.form.mainPhoto = res.url;
          this.form.uploadImg = res.path;
        });
    },
    handleAvatarSuccess(res, file) {
      this.mainPhoto = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      insurancefundFundList(queryParams)
        .then((response) => {
          response = response.data;
          this.tableData = response.list;
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        uploadImg:'',
        templateName: '',
        cityRegion: '',
        nativeIds: [],
        baseMin: '',
        baseMax: '',
        selfHousingFund: '',
        companyHousingFund: ''
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids;
      insurancefundFundInfo(id)
        .then((response) => {
          const nativeIds = _.split(response.data.nativeIds, ',');
          const filterNativeIds = [];
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < nativeIds.length; i++) {
            if (nativeIds[i] !== '') {
              filterNativeIds.push(`${nativeIds[i]}`);
            }
          }
          this.form = {
            ...response.data,
            uploadImg:'',
            cityRegion: `${response.data.cityId}-${response.data.regionId}`,
            nativeIds: filterNativeIds,
          };
          this.open = true;

          // nativeIds: filterNativeIds,
          // cityRegion: `${response.data.cityId}-${response.data.regionId}`

          this.title = '修改';
        });
    },
    enableItem(row) {
      insurancefundFundUpdate({
        id: row.id,
        dataStatus: row.dataStatus === 1 ? 2 : 1,
      })
        .then((response) => {
          this.msgSuccess('修改成功');
          this.getList();
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            nativeIds: this.form.nativeIds.join(','),
          };
          if (this.form.id != undefined) {
            insurancefundFundUpdate(params)
              .then((response) => {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              });
          } else {
            insurancefundFundSave(params)
              .then((response) => {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => insurancefundFundDelete([ids]))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      const { queryParams } = this;
      this.$confirm('是否确认导出所有会员数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => exportMember(queryParams))
        .then((response) => {
          this.download(response.data);
        })
        .catch(() => {
        });
    }
  }
};
</script>

<style lang="less" scoped>
.online-info-page {

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

}

</style>
