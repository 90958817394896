<template>
  <div class="app-container online-info-page">
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">
          新增
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column label="公司名称" align="center" prop="companyName" />
      <el-table-column label="社保缴纳城市" align="center" prop="cityList">
        <template slot-scope="{ row }">
          {{ getCityName(row.cityList) }}
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleUpdate(scope.row)">
            编辑
          </el-button>

          <el-button size="mini" type="text" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page-sizes="[16, 30, 50, 80, 100]"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.limit"
      @pagination="getList"
    />

    <!-- 添加或修改会员对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="750px">
      <el-form ref="form" :model="form" :rules="rules" label-width="140px">
        <el-form-item label="选择公司" prop="comId">
          <el-select
            :disabled="form.comId"
            class="w100"
            v-model="form.comId"
            placeholder="选择公司"
          >
            <el-option v-for="item in companyList" :label="item.companyName" :value="item.comId" />
          </el-select>
        </el-form-item>

        <el-form-item label="选择社保缴纳城市" prop="cityList">
          <el-cascader
            class="w100"
            multiple
            :props="{ multiple: true, expandTrigger: 'hover', label: 'name', value: 'id' }"
            placeholder="选择社保缴纳城市"
            v-model="form.cityList"
            :options="cityList"
          />
          <!--          <el-select class="w100" multiple v-model="form.cityList" placeholder="">-->
          <!--            <el-option v-for="item in cityList" :label="item.name" :value="item.key"/>-->
          <!--          </el-select>-->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  sysCompanyCityList,
  sysCompanyCitySave,
  sysCompanyCityUpdate,
  sysCompanyCityInfo,
  sysCompanyCityDel,
  sysCompanyGetTree
} from '@/api/offline';

import { getCityTree } from '@/api/comm';

export default {
  data() {
    return {
      companyList: [],
      cityList: [],
      cityMap: {},
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined
      },
      // 表单参数
      form: {
        comId: '',
        cityList: []
      },
      // 表单校验
      rules: {
        comId: [
          {
            required: true,
            message: '公司不能为空',
            trigger: 'blur'
          }
        ],
        cityList: [
          {
            required: true,
            message: '城市不能为空',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  created() {
    this.getList();
    this.getCityList();
    this.getCompanyList();
  },
  methods: {
    getCityList() {
      getCityTree().then(({ data }) => {
        this.cityList = this.handleCityTreeData(data);
      });
    },
    handleCityTreeData(data) {
      _.each(data, (x) => {
        this.cityMap[x.id] = x;
        if (x.children.length) {
          x.children = this.handleCityTreeData(x.children);
        } else {
          delete x.children;
        }
      });

      // 循环处理父节点
      // this.getParentsNodeList();
      // _.each(this.cityMap, (city) => {
      //   _.set(this.cityMap, [city.id, 'valuePath'], this.getParentsnODE(city.id));
      // });
      // return this.handleCityTreeData
      return data;
    },
    getParentsNodeList() {
      let parents;
      _.each(this.cityMap, (city) => {
        debugger;
        // 如果有父元素，则继续往下添加
        if (`${city.parentId}` !== '0') {
          parents = [];
          parents.unshift(...this.getParentsNodeList(city.parentId));
          _.set(this.cityMap, [city.id, 'valuePath'], parents);
        }
      });
      // return parents;
    },

    getCompanyList() {
      sysCompanyGetTree().then(({ data }) => {
        this.companyList = data;
      });
    },
    getCityName(list) {
      let result = '';
      _.each(list, (x, idx) => {
        if (idx > 0) {
          result += ',';
        }
        result += x.cityName;
      });
      return result;
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      sysCompanyCityList(queryParams).then((response) => {
        response = response.data;
        this.tableData = response.list;
        this.total = response.totalCount;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        title: '',
        mainPhoto: '',
        context: ''
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.comId;
      sysCompanyCityInfo(id).then((res) => {
        // this.form = {
        //   ...res.data,
        //   cityList: _.map(res.data.cityList, (x) => `${x.cityId}`)
        //   // cityList: _.map(res.data.cityList, x => (`${x.provinceId}-${x.cityId}`)),
        // };

        const form = {
          comId: res.data.comId,
          cityList: _.map(res.data.cityList, (x) => this.findCityPath(x.cityId))
        };

        this.form = form;
        this.open = true;
        this.title = '修改';
      });
    },

    findCityPath(id) {
      const item = this.cityMap[id];
      if (item.parentId === '0' || !item.parentId) {
        return [id];
      }
      return [...this.findCityPath(item.parentId), id];
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = {
            ...this.form,
            cityList: []
          };
          let val;
          _.each(this.form.cityList, (x) => {
            val = x[x.length - 1];
            data.cityList.push({
              ...this.cityMap[val],
              cityId: this.cityMap[val].id
            });
          });

          if (this.form.comId != undefined) {
            sysCompanyCityUpdate(data).then((response) => {
              this.msgSuccess('修改成功');
              this.open = false;
              this.getList();
            });
          } else {
            sysCompanyCitySave(data).then((response) => {
              this.msgSuccess('新增成功');
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.comId;
      this.$confirm('删除后，该模板下的城市将不能正常进行费用缴纳', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => sysCompanyCityDel([ids]))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.online-info-page {
  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
</style>
