import request from '@/utils/request';

// POST /memberReward/audit
// 审核
//
// GET /memberReward/list
// 列表

export function memberRewardList(params) {
  return request({
    url: '/memberReward/list',
    method: 'get',
    params,
  });
}

export function memberRewardAudit(data) {
  return request({
    url: '/memberReward/audit',
    method: 'post',
    data,
  });
}
