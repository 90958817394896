import request from '@/utils/request'

// 查询账单信息列表
export function listInfo(query) {
  return request({
    url: '/info/list',
    method: 'get',
    params: query
  })
}

// 查询账单信息详细
export function getInfo(id) {
  return request({
    url: `/info/info/${id}`,
    method: 'get'
  })
}

// 新增账单信息
export function addInfo(data) {
  return request({
    url: '/info/save',
    method: 'post',
    data
  })
}

// 修改账单信息
export function updateInfo(data) {
  return request({
    url: '/info/update',
    method: 'post',
    data
  })
}

// 删除账单信息
export function delInfo(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/info/delete',
    method: 'post',
    data
  })
}

// 导出账单信息
export function exportInfo(query) {
  return request({
    url: '/info/export',
    method: 'get',
    params: query
  })
}
