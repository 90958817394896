import request from '@/utils/request'

// 查询行业资讯列表
export function listNews(query) {
  return request({
    url: '/news/list',
    method: 'get',
    params: query
  })
}

// 查询行业资讯详细
export function getNews(id) {
  return request({
    url: `/news/info/${id}`,
    method: 'get'
  })
}

// 新增行业资讯
export function addNews(data) {
  return request({
    url: '/news/save',
    method: 'post',
    data
  })
}

// 修改行业资讯
export function updateNews(data) {
  return request({
    url: '/news/update',
    method: 'post',
    data
  })
}

// 删除行业资讯
export function delNews(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/news/delete',
    method: 'post',
    data
  })
}

// 导出行业资讯
export function exportNews(query) {
  return request({
    url: '/news/export',
    method: 'get',
    params: query
  })
}
