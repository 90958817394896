import request from '@/utils/request'

// 查询账单详情列表
export function listDetail(query) {
  return request({
    url: '/detail/list',
    method: 'get',
    params: query
  })
}

// 查询账单详情详细
export function getDetail(id) {
  return request({
    url: `/detail/info/${id}`,
    method: 'get'
  })
}

// 新增账单详情
export function addDetail(data) {
  return request({
    url: '/detail/save',
    method: 'post',
    data
  })
}

// 修改账单详情
export function updateDetail(data) {
  return request({
    url: '/detail/update',
    method: 'post',
    data
  })
}

// 删除账单详情
export function delDetail(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/detail/delete',
    method: 'post',
    data
  })
}

// 导出账单详情
export function exportDetail(query) {
  return request({
    url: '/detail/export',
    method: 'get',
    params: query
  })
}
