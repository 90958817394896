<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="城市id" prop="cityId">
        <el-input
          v-model="queryParams.cityId"
          placeholder="请输入城市id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="区域id" prop="regionId">
        <el-input
          v-model="queryParams.regionId"
          placeholder="请输入区域id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保新参日期" prop="insuranceEnterEndDate">
        <el-input
          v-model="queryParams.insuranceEnterEndDate"
          placeholder="请输入社保新参日期"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保新参类型" prop="insuranceEnterValidType">
        <el-select v-model="queryParams.insuranceEnterValidType" placeholder="请选择社保新参类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="社保减员日期" prop="insuranceLeaveEndDate">
        <el-input
          v-model="queryParams.insuranceLeaveEndDate"
          placeholder="请输入社保减员日期"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保减员类型" prop="insuranceLeaveValidType">
        <el-select v-model="queryParams.insuranceLeaveValidType" placeholder="请选择社保减员类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="社保转入日期" prop="insuranceChangeEnterEndDate">
        <el-input
          v-model="queryParams.insuranceChangeEnterEndDate"
          placeholder="请输入社保转入日期"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保转入类型" prop="insuranceChangeEnterValidType">
        <el-select v-model="queryParams.insuranceChangeEnterValidType" placeholder="请选择社保转入类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="社保补缴类型" prop="insurancePayBackFlag">
        <el-input
          v-model="queryParams.insurancePayBackFlag"
          placeholder="请输入社保补缴类型"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保补缴日期" prop="insurancePayBackEndDate">
        <el-input
          v-model="queryParams.insurancePayBackEndDate"
          placeholder="请输入社保补缴日期"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保允许补缴月份数" prop="insurancePayBackMonth">
        <el-input
          v-model="queryParams.insurancePayBackMonth"
          placeholder="请输入社保允许补缴月份数"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金新参日期" prop="fundEnterEndDate">
        <el-input
          v-model="queryParams.fundEnterEndDate"
          placeholder="请输入公积金新参日期"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金新参类型" prop="fundEnterValidType">
        <el-select v-model="queryParams.fundEnterValidType" placeholder="请选择公积金新参类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="公积金减员日期" prop="fundLeaveEndDate">
        <el-input
          v-model="queryParams.fundLeaveEndDate"
          placeholder="请输入公积金减员日期"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金减员类型" prop="fundLeaveValidType">
        <el-select v-model="queryParams.fundLeaveValidType" placeholder="请选择公积金减员类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="公积金转入日期" prop="fundChangeEnterEndDate">
        <el-input
          v-model="queryParams.fundChangeEnterEndDate"
          placeholder="请输入公积金转入日期"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金转入类型" prop="fundChangeEnterValidType">
        <el-select v-model="queryParams.fundChangeEnterValidType" placeholder="请选择公积金转入类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="公积金补缴类型" prop="fundPayBackFlag">
        <el-input
          v-model="queryParams.fundPayBackFlag"
          placeholder="请输入公积金补缴类型"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金补缴日期" prop="fundPayBackEndDate">
        <el-input
          v-model="queryParams.fundPayBackEndDate"
          placeholder="请输入公积金补缴日期"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金允许补缴月份数" prop="fundPayBackMonth">
        <el-input
          v-model="queryParams.fundPayBackMonth"
          placeholder="请输入公积金允许补缴月份数"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保差" prop="diffFlag">
        <el-input
          v-model="queryParams.diffFlag"
          placeholder="请输入社保差"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="大额医疗缴纳类型" prop="largeMedicalPayType">
        <el-select v-model="queryParams.largeMedicalPayType" placeholder="请选择大额医疗缴纳类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="缴纳截止日期" prop="payEndDate">
        <el-input
          v-model="queryParams.payEndDate"
          placeholder="请输入缴纳截止日期"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['system:config:add']"
        >
          新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['system:config:edit']"
        >
          修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['system:config:remove']"
        >
          删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:config:export']"
        >
          导出
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="configList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="城市id" align="center" prop="cityId" />
      <el-table-column label="区域id" align="center" prop="regionId" />
      <el-table-column label="社保新参日期" align="center" prop="insuranceEnterEndDate" />
      <el-table-column label="社保新参类型(1:当前生效;2:次月生效)" align="center" prop="insuranceEnterValidType" />
      <el-table-column label="社保减员日期" align="center" prop="insuranceLeaveEndDate" />
      <el-table-column label="社保减员类型(1:当前生效;2:次月生效)" align="center" prop="insuranceLeaveValidType" />
      <el-table-column label="社保转入日期" align="center" prop="insuranceChangeEnterEndDate" />
      <el-table-column label="社保转入类型(1:当前生效;2:次月生效)" align="center" prop="insuranceChangeEnterValidType" />
      <el-table-column label="社保补缴类型(1:允许补缴;2:不允许补缴)" align="center" prop="insurancePayBackFlag" />
      <el-table-column label="社保补缴日期" align="center" prop="insurancePayBackEndDate" />
      <el-table-column label="社保允许补缴月份数" align="center" prop="insurancePayBackMonth" />
      <el-table-column label="公积金新参日期" align="center" prop="fundEnterEndDate" />
      <el-table-column label="公积金新参类型(1:当前生效;2:次月生效)" align="center" prop="fundEnterValidType" />
      <el-table-column label="公积金减员日期" align="center" prop="fundLeaveEndDate" />
      <el-table-column label="公积金减员类型(1:当前生效;2:次月生效)" align="center" prop="fundLeaveValidType" />
      <el-table-column label="公积金转入日期" align="center" prop="fundChangeEnterEndDate" />
      <el-table-column label="公积金转入类型(1:当前生效;2:次月生效)" align="center" prop="fundChangeEnterValidType" />
      <el-table-column label="公积金补缴类型(1:允许补缴;2:不允许补缴)" align="center" prop="fundPayBackFlag" />
      <el-table-column label="公积金补缴日期" align="center" prop="fundPayBackEndDate" />
      <el-table-column label="公积金允许补缴月份数" align="center" prop="fundPayBackMonth" />
      <el-table-column label="社保差(1:有社保差;2:没有社保差)" align="center" prop="diffFlag" />
      <el-table-column label="大额医疗缴纳类型(1:按月;2:按年)" align="center" prop="largeMedicalPayType" />
      <el-table-column label="缴纳截止日期" align="center" prop="payEndDate" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:config:edit']"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:config:remove']"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改城市社保公积金配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="城市id" prop="cityId">
          <el-input v-model="form.cityId" placeholder="请输入城市id" />
        </el-form-item>
        <el-form-item label="区域id" prop="regionId">
          <el-input v-model="form.regionId" placeholder="请输入区域id" />
        </el-form-item>
        <el-form-item label="社保新参日期" prop="insuranceEnterEndDate">
          <el-input v-model="form.insuranceEnterEndDate" placeholder="请输入社保新参日期" />
        </el-form-item>
        <el-form-item label="社保新参类型(1:当前生效;2:次月生效)">
          <el-select v-model="form.insuranceEnterValidType" placeholder="请选择社保新参类型(1:当前生效;2:次月生效)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="社保减员日期" prop="insuranceLeaveEndDate">
          <el-input v-model="form.insuranceLeaveEndDate" placeholder="请输入社保减员日期" />
        </el-form-item>
        <el-form-item label="社保减员类型(1:当前生效;2:次月生效)">
          <el-select v-model="form.insuranceLeaveValidType" placeholder="请选择社保减员类型(1:当前生效;2:次月生效)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="社保转入日期" prop="insuranceChangeEnterEndDate">
          <el-input v-model="form.insuranceChangeEnterEndDate" placeholder="请输入社保转入日期" />
        </el-form-item>
        <el-form-item label="社保转入类型(1:当前生效;2:次月生效)">
          <el-select v-model="form.insuranceChangeEnterValidType" placeholder="请选择社保转入类型(1:当前生效;2:次月生效)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="社保补缴类型(1:允许补缴;2:不允许补缴)" prop="insurancePayBackFlag">
          <el-input v-model="form.insurancePayBackFlag" placeholder="请输入社保补缴类型(1:允许补缴;2:不允许补缴)" />
        </el-form-item>
        <el-form-item label="社保补缴日期" prop="insurancePayBackEndDate">
          <el-input v-model="form.insurancePayBackEndDate" placeholder="请输入社保补缴日期" />
        </el-form-item>
        <el-form-item label="社保允许补缴月份数" prop="insurancePayBackMonth">
          <el-input v-model="form.insurancePayBackMonth" placeholder="请输入社保允许补缴月份数" />
        </el-form-item>
        <el-form-item label="公积金新参日期" prop="fundEnterEndDate">
          <el-input v-model="form.fundEnterEndDate" placeholder="请输入公积金新参日期" />
        </el-form-item>
        <el-form-item label="公积金新参类型(1:当前生效;2:次月生效)">
          <el-select v-model="form.fundEnterValidType" placeholder="请选择公积金新参类型(1:当前生效;2:次月生效)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="公积金减员日期" prop="fundLeaveEndDate">
          <el-input v-model="form.fundLeaveEndDate" placeholder="请输入公积金减员日期" />
        </el-form-item>
        <el-form-item label="公积金减员类型(1:当前生效;2:次月生效)">
          <el-select v-model="form.fundLeaveValidType" placeholder="请选择公积金减员类型(1:当前生效;2:次月生效)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="公积金转入日期" prop="fundChangeEnterEndDate">
          <el-input v-model="form.fundChangeEnterEndDate" placeholder="请输入公积金转入日期" />
        </el-form-item>
        <el-form-item label="公积金转入类型(1:当前生效;2:次月生效)">
          <el-select v-model="form.fundChangeEnterValidType" placeholder="请选择公积金转入类型(1:当前生效;2:次月生效)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="公积金补缴类型(1:允许补缴;2:不允许补缴)" prop="fundPayBackFlag">
          <el-input v-model="form.fundPayBackFlag" placeholder="请输入公积金补缴类型(1:允许补缴;2:不允许补缴)" />
        </el-form-item>
        <el-form-item label="公积金补缴日期" prop="fundPayBackEndDate">
          <el-input v-model="form.fundPayBackEndDate" placeholder="请输入公积金补缴日期" />
        </el-form-item>
        <el-form-item label="公积金允许补缴月份数" prop="fundPayBackMonth">
          <el-input v-model="form.fundPayBackMonth" placeholder="请输入公积金允许补缴月份数" />
        </el-form-item>
        <el-form-item label="社保差(1:有社保差;2:没有社保差)" prop="diffFlag">
          <el-input v-model="form.diffFlag" placeholder="请输入社保差(1:有社保差;2:没有社保差)" />
        </el-form-item>
        <el-form-item label="大额医疗缴纳类型(1:按月;2:按年)">
          <el-select v-model="form.largeMedicalPayType" placeholder="请选择大额医疗缴纳类型(1:按月;2:按年)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="缴纳截止日期" prop="payEndDate">
          <el-input v-model="form.payEndDate" placeholder="请输入缴纳截止日期" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listConfig, getConfig, delConfig, addConfig, updateConfig, exportConfig
} from '@/api/system/config';

export default {
  name: 'Config',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 城市社保公积金配置表格数据
      configList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        cityId: undefined,
        regionId: undefined,
        insuranceEnterEndDate: undefined,
        insuranceEnterValidType: undefined,
        insuranceLeaveEndDate: undefined,
        insuranceLeaveValidType: undefined,
        insuranceChangeEnterEndDate: undefined,
        insuranceChangeEnterValidType: undefined,
        insurancePayBackFlag: undefined,
        insurancePayBackEndDate: undefined,
        insurancePayBackMonth: undefined,
        fundEnterEndDate: undefined,
        fundEnterValidType: undefined,
        fundLeaveEndDate: undefined,
        fundLeaveValidType: undefined,
        fundChangeEnterEndDate: undefined,
        fundChangeEnterValidType: undefined,
        fundPayBackFlag: undefined,
        fundPayBackEndDate: undefined,
        fundPayBackMonth: undefined,
        diffFlag: undefined,
        largeMedicalPayType: undefined,
        payEndDate: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        cityId: [
          { required: true, message: '城市id不能为空', trigger: 'blur' }
        ],
        regionId: [
          { required: true, message: '区域id不能为空', trigger: 'blur' }
        ],
        insuranceEnterEndDate: [
          { required: true, message: '社保新参日期不能为空', trigger: 'blur' }
        ],
        insuranceEnterValidType: [
          { required: true, message: '社保新参类型(1:当前生效;2:次月生效)不能为空', trigger: 'blur' }
        ],
        insuranceLeaveEndDate: [
          { required: true, message: '社保减员日期不能为空', trigger: 'blur' }
        ],
        insuranceLeaveValidType: [
          { required: true, message: '社保减员类型(1:当前生效;2:次月生效)不能为空', trigger: 'blur' }
        ],
        insuranceChangeEnterEndDate: [
          { required: true, message: '社保转入日期不能为空', trigger: 'blur' }
        ],
        insuranceChangeEnterValidType: [
          { required: true, message: '社保转入类型(1:当前生效;2:次月生效)不能为空', trigger: 'blur' }
        ],
        insurancePayBackFlag: [
          { required: true, message: '社保补缴类型(1:允许补缴;2:不允许补缴)不能为空', trigger: 'blur' }
        ],
        insurancePayBackEndDate: [
          { required: true, message: '社保补缴日期不能为空', trigger: 'blur' }
        ],
        insurancePayBackMonth: [
          { required: true, message: '社保允许补缴月份数不能为空', trigger: 'blur' }
        ],
        fundEnterEndDate: [
          { required: true, message: '公积金新参日期不能为空', trigger: 'blur' }
        ],
        fundEnterValidType: [
          { required: true, message: '公积金新参类型(1:当前生效;2:次月生效)不能为空', trigger: 'blur' }
        ],
        fundLeaveEndDate: [
          { required: true, message: '公积金减员日期不能为空', trigger: 'blur' }
        ],
        fundLeaveValidType: [
          { required: true, message: '公积金减员类型(1:当前生效;2:次月生效)不能为空', trigger: 'blur' }
        ],
        fundChangeEnterEndDate: [
          { required: true, message: '公积金转入日期不能为空', trigger: 'blur' }
        ],
        fundChangeEnterValidType: [
          { required: true, message: '公积金转入类型(1:当前生效;2:次月生效)不能为空', trigger: 'blur' }
        ],
        fundPayBackFlag: [
          { required: true, message: '公积金补缴类型(1:允许补缴;2:不允许补缴)不能为空', trigger: 'blur' }
        ],
        fundPayBackEndDate: [
          { required: true, message: '公积金补缴日期不能为空', trigger: 'blur' }
        ],
        fundPayBackMonth: [
          { required: true, message: '公积金允许补缴月份数不能为空', trigger: 'blur' }
        ],
        diffFlag: [
          { required: true, message: '社保差(1:有社保差;2:没有社保差)不能为空', trigger: 'blur' }
        ],
        largeMedicalPayType: [
          { required: true, message: '大额医疗缴纳类型(1:按月;2:按年)不能为空', trigger: 'blur' }
        ],
        payEndDate: [
          { required: true, message: '缴纳截止日期不能为空', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询城市社保公积金配置列表 */
    getList() {
      this.loading = true;
      listConfig(this.queryParams).then((response) => {
        response = response.data;
        this.configList = response.records;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        cityId: undefined,
        regionId: undefined,
        insuranceEnterEndDate: undefined,
        insuranceEnterValidType: undefined,
        insuranceLeaveEndDate: undefined,
        insuranceLeaveValidType: undefined,
        insuranceChangeEnterEndDate: undefined,
        insuranceChangeEnterValidType: undefined,
        insurancePayBackFlag: undefined,
        insurancePayBackEndDate: undefined,
        insurancePayBackMonth: undefined,
        fundEnterEndDate: undefined,
        fundEnterValidType: undefined,
        fundLeaveEndDate: undefined,
        fundLeaveValidType: undefined,
        fundChangeEnterEndDate: undefined,
        fundChangeEnterValidType: undefined,
        fundPayBackFlag: undefined,
        fundPayBackEndDate: undefined,
        fundPayBackMonth: undefined,
        diffFlag: undefined,
        largeMedicalPayType: undefined,
        payEndDate: undefined
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '添加城市社保公积金配置';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getConfig(id).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = '修改城市社保公积金配置';
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            updateConfig(this.form).then((response) => {
              if (response.code === '200') {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addConfig(this.form).then((response) => {
              if (response.code === '200') {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm(`是否确认删除城市社保公积金配置编号为"${ids}"的数据项?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => delConfig(ids)).then(() => {
        this.getList();
        this.msgSuccess('删除成功');
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const { queryParams } = this;
      this.$confirm('是否确认导出所有城市社保公积金配置数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => exportConfig(queryParams)).then((response) => {
        this.download(response.data);
      }).catch(() => {});
    }
  }
};
</script>
