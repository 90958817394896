import request from '@/utils/request'

// 查询分公司列表
export function listCompany(query) {
  return request({
    url: '/company/list',
    method: 'get',
    params: query
  })
}

// 查询分公司详细
export function getCompany(id) {
  return request({
    url: `/company/info/${id}`,
    method: 'get'
  })
}

// 新增分公司
export function addCompany(data) {
  return request({
    url: '/company/save',
    method: 'post',
    data
  })
}

// 修改分公司
export function updateCompany(data) {
  return request({
    url: '/company/update',
    method: 'post',
    data
  })
}

// 删除分公司
export function delCompany(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/company/delete',
    method: 'post',
    data
  })
}

// 导出分公司
export function exportCompany(query) {
  return request({
    url: '/company/export',
    method: 'get',
    params: query
  })
}
