import request from '@/utils/request'

// 查询审批步骤列表
export function listStep(query) {
  return request({
    url: '/step/list',
    method: 'get',
    params: query
  })
}

// 查询审批步骤详细
export function getStep(id) {
  return request({
    url: `/step/info/${id}`,
    method: 'get'
  })
}

// 新增审批步骤
export function addStep(data) {
  return request({
    url: '/step/save',
    method: 'post',
    data
  })
}

// 修改审批步骤
export function updateStep(data) {
  return request({
    url: '/step/update',
    method: 'post',
    data
  })
}

// 删除审批步骤
export function delStep(id) {
  const data = [];
  data.push(id);
  return request({
    url: '/step/delete',
    method: 'post',
    data
  })
}

// 导出审批步骤
export function exportStep(query) {
  return request({
    url: '/step/export',
    method: 'get',
    params: query
  })
}
