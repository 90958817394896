<template>
  <div class="app-container online-info-page">
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange">
      <el-table-column label="职位名称" align="center" prop="positionName" />
      <el-table-column label="招聘形式" align="center" prop="type" />
      <el-table-column label="工作城市" align="center" prop="cityName">
        <template slot-scope="{row}">
          <el-popover
            placement="bottom"
            trigger="hover"
            :content="row.address">
            <span slot="reference">{{ row.cityName }}</span>
          </el-popover>
        </template>
      </el-table-column>

      <!--      <el-table-column label="具体位置" align="center" prop="address"/>-->
      <el-table-column label="需求部门" align="center" prop="deptName" />
      <el-table-column label="薪资范围" align="center" prop="salary" />
      <el-table-column label="学历要求" align="center" prop="experience" />
      <el-table-column label="经验要求" align="center" prop="education" />
      <el-table-column label="招聘负责人" align="center" prop="leader" />
      <el-table-column label="开始时间" align="center" prop="startTime" width="180px" />
      <el-table-column label="状态" align="center" width="60px">
        <template slot-scope="{row}">
          <el-tag type="success" v-show="row.positionStatus === 1">正常</el-tag>
          <el-tag type="danger" v-show="row.positionStatus === 2">停止</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="180px" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleUpdate(scope.row)">
            编辑
          </el-button>

          <el-button size="mini" type="text" @click="enableItem(scope.row)">
            {{ scope.row.positionStatus === 1 ? '停止招聘' : '继续招聘' }}
          </el-button>
          <el-button size="mini" type="text" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page-sizes="[16, 30, 50, 80, 100]"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.limit"
      @pagination="getList"
    />

    <!-- 添加或修改会员对话框 -->
    <!-- 添加或修改招聘职位对话框 -->
    <el-dialog :title="title" width="1050px" :close-on-click-modal="false" :close-on-press-escape="false"
               :visible.sync="open">
      <el-form ref="form" :model="form" size="small" :rules="rules" label-width="85px">
        <el-row>
          <h3>基本信息</h3>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="职位名称" prop="positionName">
              <el-input v-model="form.positionName" placeholder="不超过50个字" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="招聘形式" prop="type">
              <el-select v-model="form.type" placeholder="请选择招聘形式">
                <el-option label="全职" value="全职" />
                <el-option label="兼职" value="兼职" />
                <el-option label="小时工" value="小时工" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工作城市" prop="cityName">
              <el-input v-model="form.cityName" placeholder="请输入城市" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="详细地址" prop="address">
              <el-input v-model="form.address" placeholder="请输入详细地址" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="需求部门" prop="deptName">
              <el-input v-model="form.deptName" placeholder="请输入详细地址" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <h3>招聘要求</h3>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="薪资范围" prop="salary">
              <el-select v-model="form.salary" placeholder="请选择薪资范围">
                <el-option label="面议" value="面议" />
                <el-option label="3 - 6K" value="3 - 6K" />
                <el-option label="6 - 8K" value="6 - 8K" />
                <el-option label="8 - 15K" value="8 - 15K" />
                <el-option label="15 - 25K" value="15 - 25K" />
                <el-option label="25以上" value="25以上" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="学历要求" prop="education">
              <el-select v-model="form.education" placeholder="请选择学历要求">
                <el-option label="不限" value="不限" />
                <el-option label="中专/中技" value="中专/中技" />
                <el-option label="高中" value="高中" />
                <el-option label="大专" value="大专" />
                <el-option label="本科" value="本科" />
                <el-option label="硕士" value="硕士" />
                <el-option label="博士" value="博士" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="经验要求" prop="experience">
              <el-select v-model="form.experience" placeholder="请选择经验要求">
                <el-option label="不限" value="不限" />
                <el-option label="在校生" value="在校生" />
                <el-option label="应届生" value="应届生" />
                <el-option label="1年以内" value="1年以内" />
                <el-option label="1-3年" value="1-3年" />
                <el-option label="3-5年" value="3-5年" />
                <el-option label="5-10年" value="5-10年" />
                <el-option label="10年以上" value="10年以上" />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="行业要求" prop="industryRequire">
              <el-input v-model="form.industryRequire" placeholder="请输入行业要求" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="招聘负责人" prop="leader">
              <el-input v-model="form.leader" placeholder="请输入招聘负责人" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="开始时间" prop="startTime">
              <el-date-picker clearable style="width: 200px"
                              v-model="form.startTime"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="选择开始时间" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="职位描述" prop="describe">
              <!--          <el-input v-model="form.describe" placeholder="请输入职位描述" />-->
              <div class="editor" style="height: 350px">
                <quill-editor
                  v-model="form.describe"
                  ref="myQuillEditor"
                  :options="{}" />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listPosition, delPosition, updatePosition, addPosition, getPosition
} from '@/api/system/position';

import { uploadFile, } from '@/api/tool/file';

export default {

  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        positionName: [
          {
            required: true,
            message: '职位名称不能为空',
            trigger: 'blur'
          },
        ],
        type: [
          {
            required: true,
            message: '招聘形式不能为空',
            trigger: 'change'
          },
        ],
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    onEditorReady(editor) { // 准备编辑器

    },
    onEditorBlur() {
    }, // 失去焦点事件
    onEditorFocus() {
    }, // 获得焦点事件
    onEditorChange() {
    }, // 内容改变事件

    fileUpload(file, fileList) {
      console.log(file, fileList);
      const data = new FormData();
      data.append('file', file.raw);
      uploadFile(data)
        .then((res) => {
          this.form.mainPhoto = res.url;
        });
    },
    handleAvatarSuccess(res, file) {
      this.mainPhoto = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      listPosition(queryParams)
        .then((response) => {
          response = response.data;
          this.tableData = response.list;
          this.total = response.totalCount;
          this.loading = false;
        });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        positionName: undefined,
        type: undefined,
        cityName: undefined,
        address: undefined,
        deptName: undefined,
        salary: undefined,
        education: undefined,
        experience: undefined,
        industryRequire: undefined,
        leader: undefined,
        startTime: undefined,
        describe: undefined,
        positionStatus: 1,
        delFlag: undefined,
        createBy: undefined,
        updateBy: undefined,
        createTime: undefined,
        updateTime: undefined
      };

      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增信息';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids;
      getPosition(id)
        .then((response) => {
          this.form = response.data;
          this.open = true;
          this.title = '修改信息';
        });
    },
    enableItem(row) {
      updatePosition({
        id: row.id,
        positionStatus: row.positionStatus === 1 ? 2 : 1,
      })
        .then((response) => {
          this.msgSuccess('修改成功');
          this.getList();
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            startTime: this.$Moment(this.form.startTime)
              .format('YYYY-MM-DD HH:mm:ss'),
          };

          if (this.form.id != undefined) {
            updatePosition(params)
              .then((response) => {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              });
          } else {
            addPosition(params)
              .then((response) => {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => delPosition(ids))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
  }
};
</script>

<style lang="less" scoped>
.online-info-page {

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

}

</style>
