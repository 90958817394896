<template>
  <div class="app-container online-info-page">
    <el-row>
      <el-col :span="6"> 姓名: {{ form.userName }}</el-col>
      <el-col :span="6"> 电话:{{ form.phone }}</el-col>
      <el-col :span="6"> 身份证：{{ form.idCard }}</el-col>
      <el-col :span="6"> 注册日期：{{ form.createTime }}</el-col>
    </el-row>
    <div style="margin-top: 20px" />
    <el-row>
      <el-col :span="6">
        是否企业员工: {{ form.empFlag === 1 ? "是" : "不是" }}
      </el-col>
      <el-col :span="6"> 推荐人数:{{ form.inviteCount }}</el-col>
      <el-col :span="6"> 被推荐人：{{ form.inviteUserName }}</el-col>
    </el-row>
    <div style="margin-top: 20px" />
    <el-tabs type="border-card">
      <el-tab-pane label="推荐详情">
        <el-table :data="recommendTable">
          <el-table-column label="姓名" align="center" prop="inviteUserName" />
          <el-table-column label="手机号" align="center" prop="phone" />
          <el-table-column label="入职状态" align="center">
            <template slot-scope="scope">
              {{ jobStatus[scope.row.memberStatus] }}
            </template>
          </el-table-column>
          <el-table-column label="邀请金额" align="center" prop="inviteMoney" />
          <el-table-column label="审核状态" align="center" prop="payFlag">
            <template slot-scope="scope">
              {{ scope.row.payFlag === 1 ? "已发放" : "未发放" }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" prop="phone">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.payFlag !== 1"
                size="mini"
                type="text"
                @click="memberPayInvite(scope.row)"
                v-hasPermi="['client:client:update']"
              >
                发放
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-dialog title="发放金额" :visible.sync="award.show" width="30%">
      <el-input type="number" v-model="award.money" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="memberPayInviteConfirm"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';

import { listCityRegions } from '@/api/comm';

import {
  sysCompanyUserList,
  sysCompanyRoleMenuList,
  sysCompanyWorkflowInfo,
  sysCompanyGetTree,
} from '@/api/offline.js';

import {
  getMember,
  memberGetInviteList,
  memberPayInvite,
} from '@/api/system/member';

export default {
  data() {
    return {
      jobStatus: {
        0: '未入职',
        1: '在职',
        2: '离职',
        3: '试用期',
      },
      award: {
        show: false,
        awardId: '',
        money: '',
      },
      recommendTable: [],
      persenTable: [],

      companyList: [],
      checkboxMap: {},
      // 遮罩层
      loading: true,
      // 选中数组
      id: '',
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,

      cityList: [],

      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      configStatus: 1,
      peopleList: [],
    };
  },
  created() {
    const { id } = this.$route.params;
    this.id = id;
    this.initData();
  },
  methods: {
    initData() {
      getMember(this.id).then((res) => {
        this.form = res.data;
      });
      memberGetInviteList({ memberId: this.id, limit: 1000000 }).then((res) => {
        this.recommendTable = res.data.list;
      });
    },
    memberPayInvite(row) {
      this.award.show = true;
      this.award.awardId = row.id;
      this.award.money = row.inviteMoney;
    },
    closeDialog() {
      this.reset();
    },
    memberPayInviteConfirm() {
      const { money, awardId } = this.award;
      if (!money) {
        this.$message.error('发放金额不能为空');
        return;
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(Number(money))) {
        this.$message.error('发放金额有误');
        return;
      }

      memberPayInvite({
        id: awardId,
        inviteMoney: Number(money),
      }).then((res) => {
        this.$message.success('发放成功');
        this.award.show = false;
        this.initData();
      });
    },
    // 取消按钮
    cancel() {
      this.$router.back();
    },
    // 表单重置
    reset() {
      this.award = {
        show: false,
        awardId: '',
        money: '',
      };
      this.resetForm('form');
    },
  },
};
</script>

<style lang="less" scoped>
.online-info-page {
  table {
    td {
      padding: 10px 5px;
      //margin:0 5px;
    }
  }

  .dialog-footer {
    width: 280px;
    margin: 10px auto;
  }

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
</style>
