<template>
  <div class="app-container online-info-page">
    <el-row>
      <el-col :span="6"> 姓名: {{ form.userName }}</el-col>
      <el-col :span="6"> 电话:{{ form.phone }}</el-col>
      <el-col :span="6"> 身份证：{{ form.idCard }}</el-col>
      <el-col :span="6"> 注册日期：{{ form.createTime }}</el-col>
    </el-row>
    <div style="margin-top: 20px"/>
    <el-row>
      <el-col :span="6">
        是否企业员工: {{ form.empFlag === 1 ? '是' : '不是' }}
      </el-col>
      <el-col :span="6"> 推荐人数:{{ form.inviteCount }}</el-col>
      <el-col :span="6"> 被推荐人：{{ form.inviteUserName }}</el-col>
    </el-row>
    <div style="margin-top: 20px"/>
    <el-tabs type="border-card">
      <el-tab-pane label="推荐详情">
        <el-table :data="recommendTable">
          <el-table-column label="姓名" align="center" prop="inviteUserName"/>
          <el-table-column label="手机号" align="center" prop="phone"/>
          <el-table-column label="memberStatus" align="center">
            <template slot-scope="scope">
              {{ jobStatus[scope.row.memberStatus] }}
            </template>
          </el-table-column>
          <el-table-column label="邀请金额" align="center" prop="inviteMoney"/>
          <el-table-column label="审核状态" align="center" prop="payFlag">
            <template slot-scope="scope">
              {{ scope.row.payFlag === 1 ? '已发放' : '未发放' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" prop="phone">
            <template slot-scope="scope">
              <!--              <el-button-->
              <!--                v-if="scope.row.payFlag !== 1"-->
              <!--                size="mini" type="text"-->
              <!--                @click="memberPayInvite(scope.row)"-->
              <!--                v-hasPermi="['client:client:update']"-->
              <!--              >-->
              <!--                发放-->
              <!--              </el-button>-->

              <!--        TODO:临时去掉了在职的判断      -->
              <el-button
                size="mini" type="text"
                @click="memberPayInvite(scope.row)"
                v-hasPermi="['client:client:update']"
              >
                发放
              </el-button>

            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      title="发放金额"
      :visible.sync="award.show"
      width="30%">
      <el-input type="number" v-model="award.money"></el-input>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="memberPayInviteConfirm">确 定</el-button>
  </span>
    </el-dialog>


  </div>
</template>

<script>
import _ from 'lodash';

import { listCityRegions } from '@/api/comm';

import {
  sysCompanyUserList,
  sysCompanyRoleMenuList,
  sysCompanyWorkflowSave,
  sysCompanyWorkflowUpdate,
  sysCompanyRoleDelete,
  sysCompanyWorkflowInfo,
  sysCompanyGetTree,
} from '@/api/offline.js';

import {
  listMember,
  getMember,
  delMember,
  addMember,
  updateMember,
  exportMember,
  memberGetInviteList,
  memberPayInvite,
} from '@/api/system/member';

export default {
  data() {
    return {
      jobStatus: {
        0: '未入职',
        1: '在职',
        2: '离职',
        3: '试用期',
      },
      award: {
        show: false,
        awardId: '',
        money: '',
      },
      recommendTable: [],
      persenTable: [],

      companyList: [],
      checkboxMap: {},
      // 遮罩层
      loading: true,
      // 选中数组
      id: '',
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,

      cityList: [],

      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      configStatus: 1,
      peopleList: [],
    };
  },
  created() {
    const { id } = this.$route.params;
    this.id = id;
    this.initData();

    // this.getList();
    // this.sysCompanyRoleMenuList();
    // this.getCompanyList();
    // this.getPeopleList();
  },
  methods: {
    initData() {
      getMember(this.id)
        .then((res) => {
          this.form = res.data;
        });
      memberGetInviteList({ limit: 1000000 })
        .then((res) => {
          this.recommendTable = res.data.list;
        });
    },

    memberPayInvite(row) {
      this.award.show = true;
      this.award.awardId = row.id;
      this.award.money = row.inviteMoney;

    },
    memberPayInviteConfirm() {
      const {
        money,
        awardId
      } = this.award;
      if (!money) {
        this.$message.error('发放金额不能为空');
        return;
      }

      // eslint-disable-next-line no-restricted-globals
      if (isNaN(Number(money))) {
        this.$message.error('发放金额有误');
        return;
      }

      memberPayInvite({
        id: awardId,
        inviteMoney: Number(money),
      })
        .then((res) => {
          this.$message.success('发放成功');
          this.award.show = false;
          this.initData();
        });
    },

    changeCompany() {
      // 编辑以后在获取人员
      _.each(this.form.stepList, (x) => {
        x.assignUserIds = '';
      });
      this.getPeopleList(this.form.comId);
    },
    addStep() {
      this.form.stepList.push({
        nodeType: 1,
        assignUserIds: [],
      });
    },
    removeStep(index) {
      this.form.stepList = _.filter(
        this.form.stepList,
        (item, idx) => idx !== index
      );
    },
    getPeopleList(comId) {
      this.loading = true;
      sysCompanyUserList({
        page: 1,
        limit: 10000,
        comId,
      })
        .then((response) => {
          response = response.data;
          this.peopleList = response.list;
        });
    },
    // 获取公司tree数据
    getCompanyList() {
      sysCompanyGetTree()
        .then(({ data }) => {
          this.companyList = data;
          // const firstData = this.companyList[0];
          // setTimeout(() => {
          //   this.$refs.elTree.setCurrentKey(firstData.tid);
          //   this.queryParams.comId = firstData.comId;
          //   this.queryParams.companyName = firstData.companyName || null;
          //   this.getList();
          // }, 800);
        });
    },
    // 获取权限
    sysCompanyRoleMenuList() {
      sysCompanyRoleMenuList()
        .then(({ data }) => {
          this.tableData = this.handleRoleTableData(data);

          const { id } = this.$route.params;
          if (this.$route.params.id !== '-1') {
            setTimeout(() => {
              document.title = '编辑角色';
              this.handleUpdate(id);
            }, 200);
          } else {
            setTimeout(() => {
              document.title = '新增角色';
              this.handleAdd();
            }, 200);
          }

          // handleRoleTableData
        });
    },

    handleRoleTableData(data) {
      const result = [];
      let tempItem;

      _.each(data, (x) => {
        if (x.type === 0) {
          tempItem = {
            label: x.name,
            menuId: x.menuId,
            value: false,
          };

          this.checkboxMap[x.menuId] = tempItem;

          result.push({
            name: tempItem,
            modules: [],
            permission: [],
            field: [],
          });
        }
      });

      _.each(result, (z) => {
        // 找出所有子层级
        const filters = _.filter(data, (x) => x.parentId === z.name.menuId);
        _.each(filters, (x) => {
          tempItem = {
            label: x.name,
            menuId: x.menuId,
            value: false,
          };

          this.checkboxMap[x.menuId] = tempItem;

          if (x.type === 1) {
            z.modules.push(tempItem);
          } else if (x.type === 2) {
            z.permission.push(tempItem);
          } else if (x.type === 3) {
            z.field.push(tempItem);
          }
        });
      });
      return result;
    },
    // 取消按钮
    cancel() {
      this.$router.back();
    },
    // 表单重置
    reset() {
      this.form = {
        comId: '',
        remark: '',
        auditType: 1,
        stepList: [
          {
            nodeType: 1,
            assignUserIds: [],
          },
        ],
      };
      this.resetForm('form');
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增信息';
      if (this.cityList.length > 0) {
        this.form.cityRegion = this.cityList[0].key;
      }
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset();
      sysCompanyWorkflowInfo(id)
        .then((res) => {
          // 1固定审批流 需要审批人
          if (`${res.data.auditType}` === '1') {
            _.each(res.data.stepList, (x) => {
              if (x.assignUserIds === '') {
                x.assignUserIds = [];
              } else {
                const ids = _.split(x.assignUserIds, ',');
                x.assignUserIds = [];
                _.each(ids, (id) => {
                  x.assignUserIds.push(Number(id));
                });
              }
            });
          } else {
            res.data.stepList = this.form.stepList;
          }

          this.form = {
            ...res.data,
            comId: `${res.data.comId}`,
          };
          // 编辑以后在获取人员
          this.getPeopleList(this.form.comId);
          this.open = true;
          this.title = '修改信息';
        });
    },
    enableItem(row) {
      sysCompanyWorkflowUpdate({
        id: row.id,
        dataStatus: row.positionStatus === 1 ? 2 : 1,
      })
        .then((response) => {
          this.msgSuccess('修改成功');
          this.getList();
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = _.cloneDeep(this.form);

          // 2 授权审批人 不需要审批人
          if (`${this.form.auditType}` === '2') {
            delete params.stepList;
          } else {
            _.each(params.stepList, (x) => {
              x.assignUserIds = _.join(x.assignUserIds, ',');
            });
          }

          if (this.form.id) {
            sysCompanyWorkflowUpdate(_.omit(params, 'dataStatus'))
              .then(
                (response) => {
                  this.msgSuccess('修改成功');
                  this.$router.back();
                  // this.open = false;
                  // this.getList();
                }
              );
          } else {
            sysCompanyWorkflowSave(params)
              .then((response) => {
                this.msgSuccess('新增成功');
                this.$router.back();
              });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => sysCompanyRoleDelete([ids]))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {
        });
    },
  },
};
</script>

<style lang="less" scoped>
.online-info-page {
  table {
    td {
      padding: 10px 5px;
      //margin:0 5px;
    }
  }

  .dialog-footer {
    width: 280px;
    margin: 10px auto;
  }

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
</style>
