<template>
  <div class="app-container online-info-page">
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="mini"
      :inline="true"
      label-width="75px"
    >
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="loading"
      :data="tableData"
      @selection-change="handleSelectionChange"
    >
      <el-table-column label="id" align="center" prop="id" width="80" />
      <el-table-column label="流程名称" align="center" prop="title" />
      <el-table-column label="描述" align="center" prop="remark" />
      <el-table-column label="所属公司" align="center" prop="companyName" />
      <el-table-column label="状态" align="center" prop="dataStatus">
        <template slot-scope="{ row }">
          {{ row.dataStatus === 1 ? "正常" : "" }}
          {{ row.dataStatus === 2 ? "禁用" : "" }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="180px"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="text" @click="handleUpdate(scope.row)">
            编辑
          </el-button>

          <el-button size="mini" type="text" @click="enableItem(scope.row)">
            {{ `${scope.row.dataStatus}` === "1" ? "禁用" : "启用" }}
          </el-button>
          <el-button size="mini" type="text" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page-sizes="[16, 30, 50, 80, 100]"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.limit"
      @pagination="pageChange"
    />
  </div>
</template>

<script>
import {
  sysCompanyWorkflowList,
  sysCompanyWorkflowSave,
  sysCompanyWorkflowUpdate,
  sysCompanyWorkflowDelete,
  sysCompanyWorkflowInfo,
} from '@/api/offline';

import { listCityRegions } from '@/api/comm';

export default {
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        page: 1,
        limit: 16,
        cityRegion: '',
        userName: undefined,
        phone: undefined,
        userPassword: undefined,
        icCard: undefined,
        empFlag: undefined,
        memberStatus: undefined,
        fromMemberId: undefined,
      },
      cityList: [],

      // 表单参数
      form: {},
      // 表单校验
    };
  },
  created() {
    const { page } = this.$route.params;
    if (page === ':page') {
      this.pageChange();
      return;
    }

    this.queryParams.page = Number(page);
    this.getList();
    // this.getCityList();
  },
  methods: {
    getCityList() {
      listCityRegions().then(({ data }) => {
        this.cityList = data;
      });
    },

    handleAvatarSuccess(res, file) {
      this.mainPhoto = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },

    pageChange() {
      this.$router.replace(`/offline/examine/${this.queryParams.page}`);
    },

    /** 查询会员列表 */
    getList() {
      this.loading = true;
      const { queryParams } = this;

      const param = {
        ...queryParams,
        cityId: _.split(queryParams.cityRegion, '-')[0],
        regionId: _.split(queryParams.cityRegion, '-')[1],
      };
      sysCompanyWorkflowList(param).then((response) => {
        response = response.data;
        this.tableData = response.list;
        this.total = response.totalCount;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        diffFlag: 2,
        largeMedicalPayType: 1,
        insuranceEnterValidType: 1,
        fundEnterValidType: 1,
        insuranceLeaveValidType: 1,
        fundLeaveValidType: 1,
        insuranceChangeEnterValidType: 1,
        fundChangeEnterValidType: 1,
        insurancePayBackMonth: 0,
        fundPayBackMonth: 0,
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push('/offline/examine/details/-1');
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push(`/offline/examine/details/${row.id}`);
    },
    enableItem(row) {
      sysCompanyWorkflowUpdate({
        id: row.id,
        dataStatus: row.dataStatus === 1 ? 2 : 1,
      }).then((response) => {
        this.msgSuccess('修改成功');
        this.getList();
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        this.form.cityId = this.form.cityRegion.split('-')[0];
        this.form.regionId = this.form.cityRegion.split('-')[1];
        if (valid) {
          if (this.form.roleId != undefined) {
            sysCompanyWorkflowUpdate(this.form).then((response) => {
              this.msgSuccess('修改成功');
              this.open = false;
              this.getList();
            });
          } else {
            sysCompanyWorkflowSave(this.form).then((response) => {
              this.msgSuccess('新增成功');
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => sysCompanyWorkflowDelete([ids]))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.online-info-page {
  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
</style>
