import request from '@/utils/request';

export function login({ username, password, captcha }) {
  return request({
    url: `/sys/login?username=${username}&password=${password}&captcha=${captcha}`,
    method: 'post',
    data: {}
  });
}

export function getInfo(token) {
  return request({
    url: '/vue-admin-template/user/info',
    method: 'get',
    params: { token }
  });
}

export function logout() {
  return request({
    url: '/vue-admin-template/user/logout',
    method: 'post'
  });
}

export function changePwd(password, newPassword) {
  return request({
    url: `/sys/user/password?password=${password}&newPassword=${newPassword}`,
    method: 'post',
  });
}
