<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="企业id" prop="comId">
        <el-input
          v-model="queryParams.comId"
          placeholder="请输入企业id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="订单id" prop="orderId">
        <el-input
          v-model="queryParams.orderId"
          placeholder="请输入订单id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保缴纳标记" prop="socialInsurancePayFlag">
        <el-input
          v-model="queryParams.socialInsurancePayFlag"
          placeholder="请输入社保缴纳标记"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金缴纳标记" prop="housingFundPayFlag">
        <el-input
          v-model="queryParams.housingFundPayFlag"
          placeholder="请输入公积金缴纳标记"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="代发工资标记" prop="salaryPayFlag">
        <el-input
          v-model="queryParams.salaryPayFlag"
          placeholder="请输入代发工资标记"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="雇员id" prop="empId">
        <el-input
          v-model="queryParams.empId"
          placeholder="请输入雇员id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="员工姓名" prop="empName">
        <el-input
          v-model="queryParams.empName"
          placeholder="请输入员工姓名"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="员工身份证号" prop="empIdCard">
        <el-input
          v-model="queryParams.empIdCard"
          placeholder="请输入员工身份证号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="员工户口类型" prop="nativeType">
        <el-select v-model="queryParams.nativeType" placeholder="请选择员工户口类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="员工类型" prop="employeeType">
        <el-select v-model="queryParams.employeeType" placeholder="请选择员工类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="城市名称" prop="cityName">
        <el-input
          v-model="queryParams.cityName"
          placeholder="请输入城市名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="区域名称" prop="regionName">
        <el-input
          v-model="queryParams.regionName"
          placeholder="请输入区域名称"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="应发工资" prop="shouldSalary">
        <el-input
          v-model="queryParams.shouldSalary"
          placeholder="请输入应发工资"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保基数" prop="socialInsuranceBase">
        <el-input
          v-model="queryParams.socialInsuranceBase"
          placeholder="请输入社保基数"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金基数" prop="housingFundBase">
        <el-input
          v-model="queryParams.housingFundBase"
          placeholder="请输入公积金基数"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="养老个人" prop="selfPension">
        <el-input
          v-model="queryParams.selfPension"
          placeholder="请输入养老个人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="医疗个人" prop="selfMedical">
        <el-input
          v-model="queryParams.selfMedical"
          placeholder="请输入医疗个人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="失业个人" prop="selfUnemployment">
        <el-input
          v-model="queryParams.selfUnemployment"
          placeholder="请输入失业个人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="工伤个人" prop="selfEmploymentInjury">
        <el-input
          v-model="queryParams.selfEmploymentInjury"
          placeholder="请输入工伤个人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="生育个人" prop="selfMaternity">
        <el-input
          v-model="queryParams.selfMaternity"
          placeholder="请输入生育个人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="大额医疗个人" prop="selfLargeMedical">
        <el-input
          v-model="queryParams.selfLargeMedical"
          placeholder="请输入大额医疗个人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="残保金个人" prop="selfDisabilityInsurance">
        <el-input
          v-model="queryParams.selfDisabilityInsurance"
          placeholder="请输入残保金个人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金个人" prop="selfHousingFund">
        <el-input
          v-model="queryParams.selfHousingFund"
          placeholder="请输入公积金个人"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保公积金个人部分合计" prop="selfTotal">
        <el-input
          v-model="queryParams.selfTotal"
          placeholder="请输入社保公积金个人部分合计"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="养老公司" prop="companyPension">
        <el-input
          v-model="queryParams.companyPension"
          placeholder="请输入养老公司"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="医疗公司" prop="companyMedical">
        <el-input
          v-model="queryParams.companyMedical"
          placeholder="请输入医疗公司"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="失业公司" prop="companyUnemployment">
        <el-input
          v-model="queryParams.companyUnemployment"
          placeholder="请输入失业公司"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="工伤公司" prop="companyEmploymentInjury">
        <el-input
          v-model="queryParams.companyEmploymentInjury"
          placeholder="请输入工伤公司"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="生育公司" prop="companyMaternity">
        <el-input
          v-model="queryParams.companyMaternity"
          placeholder="请输入生育公司"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="大额医疗公司" prop="companyLargeMedical">
        <el-input
          v-model="queryParams.companyLargeMedical"
          placeholder="请输入大额医疗公司"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="残保金公司" prop="companyDisabilityInsurance">
        <el-input
          v-model="queryParams.companyDisabilityInsurance"
          placeholder="请输入残保金公司"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金公司" prop="companyHousingFund">
        <el-input
          v-model="queryParams.companyHousingFund"
          placeholder="请输入公积金公司"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保公积金公司部分合计" prop="companyTotal">
        <el-input
          v-model="queryParams.companyTotal"
          placeholder="请输入社保公积金公司部分合计"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="大额医疗缴纳类型" prop="largeMedicalPayType">
        <el-select v-model="queryParams.largeMedicalPayType" placeholder="请选择大额医疗缴纳类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="应税工资" prop="taxSalary">
        <el-input
          v-model="queryParams.taxSalary"
          placeholder="请输入应税工资"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="扣个税" prop="reduceTax">
        <el-input
          v-model="queryParams.reduceTax"
          placeholder="请输入扣个税"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="实发工资" prop="realSalary">
        <el-input
          v-model="queryParams.realSalary"
          placeholder="请输入实发工资"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="服务费" prop="serviceCharge">
        <el-input
          v-model="queryParams.serviceCharge"
          placeholder="请输入服务费"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="滞纳金" prop="lateFee">
        <el-input
          v-model="queryParams.lateFee"
          placeholder="请输入滞纳金"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保差额" prop="socialInsuranceDiff">
        <el-input
          v-model="queryParams.socialInsuranceDiff"
          placeholder="请输入社保差额"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="合计" prop="totalMoney">
        <el-input
          v-model="queryParams.totalMoney"
          placeholder="请输入合计"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['system:detail:add']"
        >
          新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['system:detail:edit']"
        >
          修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['system:detail:remove']"
        >
          删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:detail:export']"
        >
          导出
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="detailList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="企业id" align="center" prop="comId" />
      <el-table-column label="订单id" align="center" prop="orderId" />
      <el-table-column label="社保缴纳标记(0:不缴纳;1:缴纳)" align="center" prop="socialInsurancePayFlag" />
      <el-table-column label="公积金缴纳标记(0:不缴纳;1:缴纳)" align="center" prop="housingFundPayFlag" />
      <el-table-column label="代发工资标记(0:不代发;1:代发)" align="center" prop="salaryPayFlag" />
      <el-table-column label="雇员id" align="center" prop="empId" />
      <el-table-column label="员工姓名" align="center" prop="empName" />
      <el-table-column label="员工身份证号" align="center" prop="empIdCard" />
      <el-table-column label="员工户口类型" align="center" prop="nativeType" />
      <el-table-column label="员工类型(1:社保代缴;2:五险一金;3:社保+工资代发;4:五险一金+工资代发;5:工资代发)" align="center" prop="employeeType" />
      <el-table-column label="城市名称" align="center" prop="cityName" />
      <el-table-column label="区域名称" align="center" prop="regionName" />
      <el-table-column label="应发工资" align="center" prop="shouldSalary" />
      <el-table-column label="社保基数" align="center" prop="socialInsuranceBase" />
      <el-table-column label="公积金基数" align="center" prop="housingFundBase" />
      <el-table-column label="养老个人" align="center" prop="selfPension" />
      <el-table-column label="医疗个人" align="center" prop="selfMedical" />
      <el-table-column label="失业个人" align="center" prop="selfUnemployment" />
      <el-table-column label="工伤个人" align="center" prop="selfEmploymentInjury" />
      <el-table-column label="生育个人" align="center" prop="selfMaternity" />
      <el-table-column label="大额医疗个人" align="center" prop="selfLargeMedical" />
      <el-table-column label="残保金个人" align="center" prop="selfDisabilityInsurance" />
      <el-table-column label="公积金个人" align="center" prop="selfHousingFund" />
      <el-table-column label="社保公积金个人部分合计" align="center" prop="selfTotal" />
      <el-table-column label="养老公司" align="center" prop="companyPension" />
      <el-table-column label="医疗公司" align="center" prop="companyMedical" />
      <el-table-column label="失业公司" align="center" prop="companyUnemployment" />
      <el-table-column label="工伤公司" align="center" prop="companyEmploymentInjury" />
      <el-table-column label="生育公司" align="center" prop="companyMaternity" />
      <el-table-column label="大额医疗公司" align="center" prop="companyLargeMedical" />
      <el-table-column label="残保金公司" align="center" prop="companyDisabilityInsurance" />
      <el-table-column label="公积金公司" align="center" prop="companyHousingFund" />
      <el-table-column label="社保公积金公司部分合计" align="center" prop="companyTotal" />
      <el-table-column label="大额医疗缴纳类型(1:按月;2:按年)" align="center" prop="largeMedicalPayType" />
      <el-table-column label="应税工资" align="center" prop="taxSalary" />
      <el-table-column label="扣个税" align="center" prop="reduceTax" />
      <el-table-column label="实发工资" align="center" prop="realSalary" />
      <el-table-column label="服务费" align="center" prop="serviceCharge" />
      <el-table-column label="滞纳金" align="center" prop="lateFee" />
      <el-table-column label="社保差额" align="center" prop="socialInsuranceDiff" />
      <el-table-column label="合计" align="center" prop="totalMoney" />
      <el-table-column label="额外信息" align="center" prop="extra" />
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:detail:edit']"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:detail:remove']"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改账单详情对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="企业id" prop="comId">
          <el-input v-model="form.comId" placeholder="请输入企业id" />
        </el-form-item>
        <el-form-item label="订单id" prop="orderId">
          <el-input v-model="form.orderId" placeholder="请输入订单id" />
        </el-form-item>
        <el-form-item label="社保缴纳标记(0:不缴纳;1:缴纳)" prop="socialInsurancePayFlag">
          <el-input v-model="form.socialInsurancePayFlag" placeholder="请输入社保缴纳标记(0:不缴纳;1:缴纳)" />
        </el-form-item>
        <el-form-item label="公积金缴纳标记(0:不缴纳;1:缴纳)" prop="housingFundPayFlag">
          <el-input v-model="form.housingFundPayFlag" placeholder="请输入公积金缴纳标记(0:不缴纳;1:缴纳)" />
        </el-form-item>
        <el-form-item label="代发工资标记(0:不代发;1:代发)" prop="salaryPayFlag">
          <el-input v-model="form.salaryPayFlag" placeholder="请输入代发工资标记(0:不代发;1:代发)" />
        </el-form-item>
        <el-form-item label="雇员id" prop="empId">
          <el-input v-model="form.empId" placeholder="请输入雇员id" />
        </el-form-item>
        <el-form-item label="员工姓名" prop="empName">
          <el-input v-model="form.empName" placeholder="请输入员工姓名" />
        </el-form-item>
        <el-form-item label="员工身份证号" prop="empIdCard">
          <el-input v-model="form.empIdCard" placeholder="请输入员工身份证号" />
        </el-form-item>
        <el-form-item label="员工户口类型">
          <el-select v-model="form.nativeType" placeholder="请选择员工户口类型">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="员工类型(1:社保代缴;2:五险一金;3:社保+工资代发;4:五险一金+工资代发;5:工资代发)">
          <el-select v-model="form.employeeType" placeholder="请选择员工类型(1:社保代缴;2:五险一金;3:社保+工资代发;4:五险一金+工资代发;5:工资代发)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="城市名称" prop="cityName">
          <el-input v-model="form.cityName" placeholder="请输入城市名称" />
        </el-form-item>
        <el-form-item label="区域名称" prop="regionName">
          <el-input v-model="form.regionName" placeholder="请输入区域名称" />
        </el-form-item>
        <el-form-item label="应发工资" prop="shouldSalary">
          <el-input v-model="form.shouldSalary" placeholder="请输入应发工资" />
        </el-form-item>
        <el-form-item label="社保基数" prop="socialInsuranceBase">
          <el-input v-model="form.socialInsuranceBase" placeholder="请输入社保基数" />
        </el-form-item>
        <el-form-item label="公积金基数" prop="housingFundBase">
          <el-input v-model="form.housingFundBase" placeholder="请输入公积金基数" />
        </el-form-item>
        <el-form-item label="养老个人" prop="selfPension">
          <el-input v-model="form.selfPension" placeholder="请输入养老个人" />
        </el-form-item>
        <el-form-item label="医疗个人" prop="selfMedical">
          <el-input v-model="form.selfMedical" placeholder="请输入医疗个人" />
        </el-form-item>
        <el-form-item label="失业个人" prop="selfUnemployment">
          <el-input v-model="form.selfUnemployment" placeholder="请输入失业个人" />
        </el-form-item>
        <el-form-item label="工伤个人" prop="selfEmploymentInjury">
          <el-input v-model="form.selfEmploymentInjury" placeholder="请输入工伤个人" />
        </el-form-item>
        <el-form-item label="生育个人" prop="selfMaternity">
          <el-input v-model="form.selfMaternity" placeholder="请输入生育个人" />
        </el-form-item>
        <el-form-item label="大额医疗个人" prop="selfLargeMedical">
          <el-input v-model="form.selfLargeMedical" placeholder="请输入大额医疗个人" />
        </el-form-item>
        <el-form-item label="残保金个人" prop="selfDisabilityInsurance">
          <el-input v-model="form.selfDisabilityInsurance" placeholder="请输入残保金个人" />
        </el-form-item>
        <el-form-item label="公积金个人" prop="selfHousingFund">
          <el-input v-model="form.selfHousingFund" placeholder="请输入公积金个人" />
        </el-form-item>
        <el-form-item label="社保公积金个人部分合计" prop="selfTotal">
          <el-input v-model="form.selfTotal" placeholder="请输入社保公积金个人部分合计" />
        </el-form-item>
        <el-form-item label="养老公司" prop="companyPension">
          <el-input v-model="form.companyPension" placeholder="请输入养老公司" />
        </el-form-item>
        <el-form-item label="医疗公司" prop="companyMedical">
          <el-input v-model="form.companyMedical" placeholder="请输入医疗公司" />
        </el-form-item>
        <el-form-item label="失业公司" prop="companyUnemployment">
          <el-input v-model="form.companyUnemployment" placeholder="请输入失业公司" />
        </el-form-item>
        <el-form-item label="工伤公司" prop="companyEmploymentInjury">
          <el-input v-model="form.companyEmploymentInjury" placeholder="请输入工伤公司" />
        </el-form-item>
        <el-form-item label="生育公司" prop="companyMaternity">
          <el-input v-model="form.companyMaternity" placeholder="请输入生育公司" />
        </el-form-item>
        <el-form-item label="大额医疗公司" prop="companyLargeMedical">
          <el-input v-model="form.companyLargeMedical" placeholder="请输入大额医疗公司" />
        </el-form-item>
        <el-form-item label="残保金公司" prop="companyDisabilityInsurance">
          <el-input v-model="form.companyDisabilityInsurance" placeholder="请输入残保金公司" />
        </el-form-item>
        <el-form-item label="公积金公司" prop="companyHousingFund">
          <el-input v-model="form.companyHousingFund" placeholder="请输入公积金公司" />
        </el-form-item>
        <el-form-item label="社保公积金公司部分合计" prop="companyTotal">
          <el-input v-model="form.companyTotal" placeholder="请输入社保公积金公司部分合计" />
        </el-form-item>
        <el-form-item label="大额医疗缴纳类型(1:按月;2:按年)">
          <el-select v-model="form.largeMedicalPayType" placeholder="请选择大额医疗缴纳类型(1:按月;2:按年)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="应税工资" prop="taxSalary">
          <el-input v-model="form.taxSalary" placeholder="请输入应税工资" />
        </el-form-item>
        <el-form-item label="扣个税" prop="reduceTax">
          <el-input v-model="form.reduceTax" placeholder="请输入扣个税" />
        </el-form-item>
        <el-form-item label="实发工资" prop="realSalary">
          <el-input v-model="form.realSalary" placeholder="请输入实发工资" />
        </el-form-item>
        <el-form-item label="服务费" prop="serviceCharge">
          <el-input v-model="form.serviceCharge" placeholder="请输入服务费" />
        </el-form-item>
        <el-form-item label="滞纳金" prop="lateFee">
          <el-input v-model="form.lateFee" placeholder="请输入滞纳金" />
        </el-form-item>
        <el-form-item label="社保差额" prop="socialInsuranceDiff">
          <el-input v-model="form.socialInsuranceDiff" placeholder="请输入社保差额" />
        </el-form-item>
        <el-form-item label="合计" prop="totalMoney">
          <el-input v-model="form.totalMoney" placeholder="请输入合计" />
        </el-form-item>
        <el-form-item label="额外信息" prop="extra">
          <el-input v-model="form.extra" type="textarea" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="创建时间" prop="createTime">
          <el-date-picker clearable size="small" style="width: 200px"
                          v-model="form.createTime"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择创建时间" />
        </el-form-item>
        <el-form-item label="修改时间" prop="updateTime">
          <el-date-picker clearable size="small" style="width: 200px"
                          v-model="form.updateTime"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择修改时间" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listDetail, getDetail, delDetail, addDetail, updateDetail, exportDetail
} from '@/api/system/detail';

export default {
  name: 'Detail',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 账单详情表格数据
      detailList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        comId: undefined,
        orderId: undefined,
        socialInsurancePayFlag: undefined,
        housingFundPayFlag: undefined,
        salaryPayFlag: undefined,
        empId: undefined,
        empName: undefined,
        empIdCard: undefined,
        nativeType: undefined,
        employeeType: undefined,
        cityName: undefined,
        regionName: undefined,
        shouldSalary: undefined,
        socialInsuranceBase: undefined,
        housingFundBase: undefined,
        selfPension: undefined,
        selfMedical: undefined,
        selfUnemployment: undefined,
        selfEmploymentInjury: undefined,
        selfMaternity: undefined,
        selfLargeMedical: undefined,
        selfDisabilityInsurance: undefined,
        selfHousingFund: undefined,
        selfTotal: undefined,
        companyPension: undefined,
        companyMedical: undefined,
        companyUnemployment: undefined,
        companyEmploymentInjury: undefined,
        companyMaternity: undefined,
        companyLargeMedical: undefined,
        companyDisabilityInsurance: undefined,
        companyHousingFund: undefined,
        companyTotal: undefined,
        largeMedicalPayType: undefined,
        taxSalary: undefined,
        reduceTax: undefined,
        realSalary: undefined,
        serviceCharge: undefined,
        lateFee: undefined,
        socialInsuranceDiff: undefined,
        totalMoney: undefined,
        extra: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        comId: [
          { required: true, message: '企业id不能为空', trigger: 'blur' }
        ],
        orderId: [
          { required: true, message: '订单id不能为空', trigger: 'blur' }
        ],
        socialInsurancePayFlag: [
          { required: true, message: '社保缴纳标记(0:不缴纳;1:缴纳)不能为空', trigger: 'blur' }
        ],
        housingFundPayFlag: [
          { required: true, message: '公积金缴纳标记(0:不缴纳;1:缴纳)不能为空', trigger: 'blur' }
        ],
        salaryPayFlag: [
          { required: true, message: '代发工资标记(0:不代发;1:代发)不能为空', trigger: 'blur' }
        ],
        empId: [
          { required: true, message: '雇员id不能为空', trigger: 'blur' }
        ],
        empName: [
          { required: true, message: '员工姓名不能为空', trigger: 'blur' }
        ],
        empIdCard: [
          { required: true, message: '员工身份证号不能为空', trigger: 'blur' }
        ],
        nativeType: [
          { required: true, message: '员工户口类型不能为空', trigger: 'blur' }
        ],
        employeeType: [
          { required: true, message: '员工类型(1:社保代缴;2:五险一金;3:社保+工资代发;4:五险一金+工资代发;5:工资代发)不能为空', trigger: 'blur' }
        ],
        cityName: [
          { required: true, message: '城市名称不能为空', trigger: 'blur' }
        ],
        regionName: [
          { required: true, message: '区域名称不能为空', trigger: 'blur' }
        ],
        shouldSalary: [
          { required: true, message: '应发工资不能为空', trigger: 'blur' }
        ],
        socialInsuranceBase: [
          { required: true, message: '社保基数不能为空', trigger: 'blur' }
        ],
        housingFundBase: [
          { required: true, message: '公积金基数不能为空', trigger: 'blur' }
        ],
        selfPension: [
          { required: true, message: '养老个人不能为空', trigger: 'blur' }
        ],
        selfMedical: [
          { required: true, message: '医疗个人不能为空', trigger: 'blur' }
        ],
        selfUnemployment: [
          { required: true, message: '失业个人不能为空', trigger: 'blur' }
        ],
        selfEmploymentInjury: [
          { required: true, message: '工伤个人不能为空', trigger: 'blur' }
        ],
        selfMaternity: [
          { required: true, message: '生育个人不能为空', trigger: 'blur' }
        ],
        selfLargeMedical: [
          { required: true, message: '大额医疗个人不能为空', trigger: 'blur' }
        ],
        selfDisabilityInsurance: [
          { required: true, message: '残保金个人不能为空', trigger: 'blur' }
        ],
        selfHousingFund: [
          { required: true, message: '公积金个人不能为空', trigger: 'blur' }
        ],
        selfTotal: [
          { required: true, message: '社保公积金个人部分合计不能为空', trigger: 'blur' }
        ],
        companyPension: [
          { required: true, message: '养老公司不能为空', trigger: 'blur' }
        ],
        companyMedical: [
          { required: true, message: '医疗公司不能为空', trigger: 'blur' }
        ],
        companyUnemployment: [
          { required: true, message: '失业公司不能为空', trigger: 'blur' }
        ],
        companyEmploymentInjury: [
          { required: true, message: '工伤公司不能为空', trigger: 'blur' }
        ],
        companyMaternity: [
          { required: true, message: '生育公司不能为空', trigger: 'blur' }
        ],
        companyLargeMedical: [
          { required: true, message: '大额医疗公司不能为空', trigger: 'blur' }
        ],
        companyDisabilityInsurance: [
          { required: true, message: '残保金公司不能为空', trigger: 'blur' }
        ],
        companyHousingFund: [
          { required: true, message: '公积金公司不能为空', trigger: 'blur' }
        ],
        companyTotal: [
          { required: true, message: '社保公积金公司部分合计不能为空', trigger: 'blur' }
        ],
        largeMedicalPayType: [
          { required: true, message: '大额医疗缴纳类型(1:按月;2:按年)不能为空', trigger: 'blur' }
        ],
        taxSalary: [
          { required: true, message: '应税工资不能为空', trigger: 'blur' }
        ],
        reduceTax: [
          { required: true, message: '扣个税不能为空', trigger: 'blur' }
        ],
        realSalary: [
          { required: true, message: '实发工资不能为空', trigger: 'blur' }
        ],
        serviceCharge: [
          { required: true, message: '服务费不能为空', trigger: 'blur' }
        ],
        lateFee: [
          { required: true, message: '滞纳金不能为空', trigger: 'blur' }
        ],
        socialInsuranceDiff: [
          { required: true, message: '社保差额不能为空', trigger: 'blur' }
        ],
        totalMoney: [
          { required: true, message: '合计不能为空', trigger: 'blur' }
        ],
        extra: [
          { required: true, message: '额外信息不能为空', trigger: 'blur' }
        ],
        createTime: [
          { required: true, message: '创建时间不能为空', trigger: 'blur' }
        ],
        updateTime: [
          { required: true, message: '修改时间不能为空', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询账单详情列表 */
    getList() {
      this.loading = true;
      listDetail(this.queryParams).then((response) => {
        response = response.data;
        this.detailList = response.records;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        comId: undefined,
        orderId: undefined,
        socialInsurancePayFlag: undefined,
        housingFundPayFlag: undefined,
        salaryPayFlag: undefined,
        empId: undefined,
        empName: undefined,
        empIdCard: undefined,
        nativeType: undefined,
        employeeType: undefined,
        cityName: undefined,
        regionName: undefined,
        shouldSalary: undefined,
        socialInsuranceBase: undefined,
        housingFundBase: undefined,
        selfPension: undefined,
        selfMedical: undefined,
        selfUnemployment: undefined,
        selfEmploymentInjury: undefined,
        selfMaternity: undefined,
        selfLargeMedical: undefined,
        selfDisabilityInsurance: undefined,
        selfHousingFund: undefined,
        selfTotal: undefined,
        companyPension: undefined,
        companyMedical: undefined,
        companyUnemployment: undefined,
        companyEmploymentInjury: undefined,
        companyMaternity: undefined,
        companyLargeMedical: undefined,
        companyDisabilityInsurance: undefined,
        companyHousingFund: undefined,
        companyTotal: undefined,
        largeMedicalPayType: undefined,
        taxSalary: undefined,
        reduceTax: undefined,
        realSalary: undefined,
        serviceCharge: undefined,
        lateFee: undefined,
        socialInsuranceDiff: undefined,
        totalMoney: undefined,
        extra: undefined,
        createTime: undefined,
        updateTime: undefined
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '添加账单详情';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getDetail(id).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = '修改账单详情';
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            updateDetail(this.form).then((response) => {
              if (response.code === '200') {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addDetail(this.form).then((response) => {
              if (response.code === '200') {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm(`是否确认删除账单详情编号为"${ids}"的数据项?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => delDetail(ids)).then(() => {
        this.getList();
        this.msgSuccess('删除成功');
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const { queryParams } = this;
      this.$confirm('是否确认导出所有账单详情数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => exportDetail(queryParams)).then((response) => {
        this.download(response.data);
      }).catch(() => {});
    }
  }
};
</script>
