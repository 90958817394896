<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px">
      <el-form-item label="企业id" prop="comId">
        <el-input
          v-model="queryParams.comId"
          placeholder="请输入企业id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="雇员id" prop="empId">
        <el-input
          v-model="queryParams.empId"
          placeholder="请输入雇员id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="订单编号" prop="orderCode">
        <el-input
          v-model="queryParams.orderCode"
          placeholder="请输入订单编号"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="订单标题" prop="orderTopic">
        <el-input
          v-model="queryParams.orderTopic"
          placeholder="请输入订单标题"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="缴纳类型" prop="payType">
        <el-select v-model="queryParams.payType" placeholder="请选择缴纳类型" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="缴纳月份" prop="payMonth">
        <el-input
          v-model="queryParams.payMonth"
          placeholder="请输入缴纳月份"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保缴纳标记" prop="socialInsurancePayFlag">
        <el-input
          v-model="queryParams.socialInsurancePayFlag"
          placeholder="请输入社保缴纳标记"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公积金缴纳标记" prop="housingFundPayFlag">
        <el-input
          v-model="queryParams.housingFundPayFlag"
          placeholder="请输入公积金缴纳标记"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="代发工资标记" prop="salaryPayFlag">
        <el-input
          v-model="queryParams.salaryPayFlag"
          placeholder="请输入代发工资标记"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="订单状态" prop="orderStatus">
        <el-select v-model="queryParams.orderStatus" placeholder="请选择订单状态" clearable size="small">
          <el-option label="请选择字典生成" value="" />
        </el-select>
      </el-form-item>
      <el-form-item label="增值税费" prop="addedTax">
        <el-input
          v-model="queryParams.addedTax"
          placeholder="请输入增值税费"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="社保差额" prop="socialInsuranceDiff">
        <el-input
          v-model="queryParams.socialInsuranceDiff"
          placeholder="请输入社保差额"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="滞纳金" prop="lateFee">
        <el-input
          v-model="queryParams.lateFee"
          placeholder="请输入滞纳金"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="服务费" prop="serviceCharge">
        <el-input
          v-model="queryParams.serviceCharge"
          placeholder="请输入服务费"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="订单总额" prop="totalMoney">
        <el-input
          v-model="queryParams.totalMoney"
          placeholder="请输入订单总额"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="开发票标记" prop="ticketFlag">
        <el-input
          v-model="queryParams.ticketFlag"
          placeholder="请输入开发票标记"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="创建人id" prop="createUserId">
        <el-input
          v-model="queryParams.createUserId"
          placeholder="请输入创建人id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="创建人姓名" prop="createUserName">
        <el-input
          v-model="queryParams.createUserName"
          placeholder="请输入创建人姓名"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="修改人id" prop="updateUserId">
        <el-input
          v-model="queryParams.updateUserId"
          placeholder="请输入修改人id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="修改人姓名" prop="updateUserName">
        <el-input
          v-model="queryParams.updateUserName"
          placeholder="请输入修改人姓名"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="审核人id" prop="auditUserId">
        <el-input
          v-model="queryParams.auditUserId"
          placeholder="请输入审核人id"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="审核人姓名" prop="auditUserName">
        <el-input
          v-model="queryParams.auditUserName"
          placeholder="请输入审核人姓名"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="审核时间" prop="auditTime">
        <el-date-picker clearable size="small" style="width: 200px"
                        v-model="queryParams.auditTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择审核时间" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['system:info:add']"
        >
          新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['system:info:edit']"
        >
          修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['system:info:remove']"
        >
          删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:info:export']"
        >
          导出
        </el-button>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="infoList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="企业id" align="center" prop="comId" />
      <el-table-column label="雇员id" align="center" prop="empId" />
      <el-table-column label="订单编号" align="center" prop="orderCode" />
      <el-table-column label="订单标题" align="center" prop="orderTopic" />
      <el-table-column label="缴纳类型(1:缴纳;2:预缴;3:补缴)" align="center" prop="payType" />
      <el-table-column label="缴纳月份" align="center" prop="payMonth" />
      <el-table-column label="社保缴纳标记(0:不缴纳;1:缴纳)" align="center" prop="socialInsurancePayFlag" />
      <el-table-column label="公积金缴纳标记(0:不缴纳;1:缴纳)" align="center" prop="housingFundPayFlag" />
      <el-table-column label="代发工资标记(0:不代发;1:代发)" align="center" prop="salaryPayFlag" />
      <el-table-column label="订单状态(1:审核中,2:审核通过.3:审核失败,4:待支付,5:支付完成)" align="center" prop="orderStatus" />
      <el-table-column label="增值税费" align="center" prop="addedTax" />
      <el-table-column label="社保差额" align="center" prop="socialInsuranceDiff" />
      <el-table-column label="滞纳金" align="center" prop="lateFee" />
      <el-table-column label="服务费" align="center" prop="serviceCharge" />
      <el-table-column label="订单总额" align="center" prop="totalMoney" />
      <el-table-column label="备注" align="center" prop="remark" />
      <el-table-column label="开发票标记(0:未开;1:已申请;2:已开票)" align="center" prop="ticketFlag" />
      <el-table-column label="额外信息" align="center" prop="extra" />
      <el-table-column label="创建人id" align="center" prop="createUserId" />
      <el-table-column label="创建人姓名" align="center" prop="createUserName" />
      <el-table-column label="修改人id" align="center" prop="updateUserId" />
      <el-table-column label="修改人姓名" align="center" prop="updateUserName" />
      <el-table-column label="审核人id" align="center" prop="auditUserId" />
      <el-table-column label="审核人姓名" align="center" prop="auditUserName" />
      <el-table-column label="审核时间" align="center" prop="auditTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.auditTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:info:edit']"
          >
            修改
          </el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['system:info:remove']"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改账单信息对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="企业id" prop="comId">
          <el-input v-model="form.comId" placeholder="请输入企业id" />
        </el-form-item>
        <el-form-item label="雇员id" prop="empId">
          <el-input v-model="form.empId" placeholder="请输入雇员id" />
        </el-form-item>
        <el-form-item label="订单编号" prop="orderCode">
          <el-input v-model="form.orderCode" placeholder="请输入订单编号" />
        </el-form-item>
        <el-form-item label="订单标题" prop="orderTopic">
          <el-input v-model="form.orderTopic" placeholder="请输入订单标题" />
        </el-form-item>
        <el-form-item label="缴纳类型(1:缴纳;2:预缴;3:补缴)">
          <el-select v-model="form.payType" placeholder="请选择缴纳类型(1:缴纳;2:预缴;3:补缴)">
            <el-option label="请选择字典生成" value="" />
          </el-select>
        </el-form-item>
        <el-form-item label="缴纳月份" prop="payMonth">
          <el-input v-model="form.payMonth" placeholder="请输入缴纳月份" />
        </el-form-item>
        <el-form-item label="社保缴纳标记(0:不缴纳;1:缴纳)" prop="socialInsurancePayFlag">
          <el-input v-model="form.socialInsurancePayFlag" placeholder="请输入社保缴纳标记(0:不缴纳;1:缴纳)" />
        </el-form-item>
        <el-form-item label="公积金缴纳标记(0:不缴纳;1:缴纳)" prop="housingFundPayFlag">
          <el-input v-model="form.housingFundPayFlag" placeholder="请输入公积金缴纳标记(0:不缴纳;1:缴纳)" />
        </el-form-item>
        <el-form-item label="代发工资标记(0:不代发;1:代发)" prop="salaryPayFlag">
          <el-input v-model="form.salaryPayFlag" placeholder="请输入代发工资标记(0:不代发;1:代发)" />
        </el-form-item>
        <el-form-item label="订单状态(1:审核中,2:审核通过.3:审核失败,4:待支付,5:支付完成)">
          <el-radio-group v-model="form.orderStatus">
            <el-radio label="1">请选择字典生成</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="增值税费" prop="addedTax">
          <el-input v-model="form.addedTax" placeholder="请输入增值税费" />
        </el-form-item>
        <el-form-item label="社保差额" prop="socialInsuranceDiff">
          <el-input v-model="form.socialInsuranceDiff" placeholder="请输入社保差额" />
        </el-form-item>
        <el-form-item label="滞纳金" prop="lateFee">
          <el-input v-model="form.lateFee" placeholder="请输入滞纳金" />
        </el-form-item>
        <el-form-item label="服务费" prop="serviceCharge">
          <el-input v-model="form.serviceCharge" placeholder="请输入服务费" />
        </el-form-item>
        <el-form-item label="订单总额" prop="totalMoney">
          <el-input v-model="form.totalMoney" placeholder="请输入订单总额" />
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" />
        </el-form-item>
        <el-form-item label="开发票标记(0:未开;1:已申请;2:已开票)" prop="ticketFlag">
          <el-input v-model="form.ticketFlag" placeholder="请输入开发票标记(0:未开;1:已申请;2:已开票)" />
        </el-form-item>
        <el-form-item label="额外信息" prop="extra">
          <el-input v-model="form.extra" type="textarea" placeholder="请输入内容" />
        </el-form-item>
        <el-form-item label="创建人id" prop="createUserId">
          <el-input v-model="form.createUserId" placeholder="请输入创建人id" />
        </el-form-item>
        <el-form-item label="创建人姓名" prop="createUserName">
          <el-input v-model="form.createUserName" placeholder="请输入创建人姓名" />
        </el-form-item>
        <el-form-item label="修改人id" prop="updateUserId">
          <el-input v-model="form.updateUserId" placeholder="请输入修改人id" />
        </el-form-item>
        <el-form-item label="修改人姓名" prop="updateUserName">
          <el-input v-model="form.updateUserName" placeholder="请输入修改人姓名" />
        </el-form-item>
        <el-form-item label="审核人id" prop="auditUserId">
          <el-input v-model="form.auditUserId" placeholder="请输入审核人id" />
        </el-form-item>
        <el-form-item label="审核人姓名" prop="auditUserName">
          <el-input v-model="form.auditUserName" placeholder="请输入审核人姓名" />
        </el-form-item>
        <el-form-item label="审核时间" prop="auditTime">
          <el-date-picker clearable size="small" style="width: 200px"
                          v-model="form.auditTime"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择审核时间" />
        </el-form-item>
        <el-form-item label="创建时间" prop="createTime">
          <el-date-picker clearable size="small" style="width: 200px"
                          v-model="form.createTime"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择创建时间" />
        </el-form-item>
        <el-form-item label="修改时间" prop="updateTime">
          <el-date-picker clearable size="small" style="width: 200px"
                          v-model="form.updateTime"
                          type="date"
                          value-format="yyyy-MM-dd"
                          placeholder="选择修改时间" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listInfo, getInfo, delInfo, addInfo, updateInfo, exportInfo
} from '@/api/system/info';

export default {
  name: 'Info',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 账单信息表格数据
      infoList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        comId: undefined,
        empId: undefined,
        orderCode: undefined,
        orderTopic: undefined,
        payType: undefined,
        payMonth: undefined,
        socialInsurancePayFlag: undefined,
        housingFundPayFlag: undefined,
        salaryPayFlag: undefined,
        orderStatus: undefined,
        addedTax: undefined,
        socialInsuranceDiff: undefined,
        lateFee: undefined,
        serviceCharge: undefined,
        totalMoney: undefined,
        ticketFlag: undefined,
        extra: undefined,
        createUserId: undefined,
        createUserName: undefined,
        updateUserId: undefined,
        updateUserName: undefined,
        auditUserId: undefined,
        auditUserName: undefined,
        auditTime: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        comId: [
          { required: true, message: '企业id不能为空', trigger: 'blur' }
        ],
        empId: [
          { required: true, message: '雇员id不能为空', trigger: 'blur' }
        ],
        orderCode: [
          { required: true, message: '订单编号不能为空', trigger: 'blur' }
        ],
        orderTopic: [
          { required: true, message: '订单标题不能为空', trigger: 'blur' }
        ],
        payType: [
          { required: true, message: '缴纳类型(1:缴纳;2:预缴;3:补缴)不能为空', trigger: 'blur' }
        ],
        payMonth: [
          { required: true, message: '缴纳月份不能为空', trigger: 'blur' }
        ],
        socialInsurancePayFlag: [
          { required: true, message: '社保缴纳标记(0:不缴纳;1:缴纳)不能为空', trigger: 'blur' }
        ],
        housingFundPayFlag: [
          { required: true, message: '公积金缴纳标记(0:不缴纳;1:缴纳)不能为空', trigger: 'blur' }
        ],
        salaryPayFlag: [
          { required: true, message: '代发工资标记(0:不代发;1:代发)不能为空', trigger: 'blur' }
        ],
        orderStatus: [
          { required: true, message: '订单状态(1:审核中,2:审核通过.3:审核失败,4:待支付,5:支付完成)不能为空', trigger: 'blur' }
        ],
        addedTax: [
          { required: true, message: '增值税费不能为空', trigger: 'blur' }
        ],
        socialInsuranceDiff: [
          { required: true, message: '社保差额不能为空', trigger: 'blur' }
        ],
        lateFee: [
          { required: true, message: '滞纳金不能为空', trigger: 'blur' }
        ],
        serviceCharge: [
          { required: true, message: '服务费不能为空', trigger: 'blur' }
        ],
        totalMoney: [
          { required: true, message: '订单总额不能为空', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '备注不能为空', trigger: 'blur' }
        ],
        ticketFlag: [
          { required: true, message: '开发票标记(0:未开;1:已申请;2:已开票)不能为空', trigger: 'blur' }
        ],
        extra: [
          { required: true, message: '额外信息不能为空', trigger: 'blur' }
        ],
        createUserId: [
          { required: true, message: '创建人id不能为空', trigger: 'blur' }
        ],
        createUserName: [
          { required: true, message: '创建人姓名不能为空', trigger: 'blur' }
        ],
        updateUserId: [
          { required: true, message: '修改人id不能为空', trigger: 'blur' }
        ],
        updateUserName: [
          { required: true, message: '修改人姓名不能为空', trigger: 'blur' }
        ],
        auditUserId: [
          { required: true, message: '审核人id不能为空', trigger: 'blur' }
        ],
        auditUserName: [
          { required: true, message: '审核人姓名不能为空', trigger: 'blur' }
        ],
        auditTime: [
          { required: true, message: '审核时间不能为空', trigger: 'blur' }
        ],
        createTime: [
          { required: true, message: '创建时间不能为空', trigger: 'blur' }
        ],
        updateTime: [
          { required: true, message: '修改时间不能为空', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询账单信息列表 */
    getList() {
      this.loading = true;
      listInfo(this.queryParams).then((response) => {
        response = response.data;
        this.infoList = response.records;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: undefined,
        comId: undefined,
        empId: undefined,
        orderCode: undefined,
        orderTopic: undefined,
        payType: undefined,
        payMonth: undefined,
        socialInsurancePayFlag: undefined,
        housingFundPayFlag: undefined,
        salaryPayFlag: undefined,
        orderStatus: '0',
        addedTax: undefined,
        socialInsuranceDiff: undefined,
        lateFee: undefined,
        serviceCharge: undefined,
        totalMoney: undefined,
        remark: undefined,
        ticketFlag: undefined,
        extra: undefined,
        createUserId: undefined,
        createUserName: undefined,
        updateUserId: undefined,
        updateUserName: undefined,
        auditUserId: undefined,
        auditUserName: undefined,
        auditTime: undefined,
        createTime: undefined,
        updateTime: undefined
      };
      this.resetForm('form');
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm');
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '添加账单信息';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getInfo(id).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = '修改账单信息';
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id != undefined) {
            updateInfo(this.form).then((response) => {
              if (response.code === '200') {
                this.msgSuccess('修改成功');
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          } else {
            addInfo(this.form).then((response) => {
              if (response.code === '200') {
                this.msgSuccess('新增成功');
                this.open = false;
                this.getList();
              } else {
                this.msgError(response.msg);
              }
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm(`是否确认删除账单信息编号为"${ids}"的数据项?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => delInfo(ids)).then(() => {
        this.getList();
        this.msgSuccess('删除成功');
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      const { queryParams } = this;
      this.$confirm('是否确认导出所有账单信息数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => exportInfo(queryParams)).then((response) => {
        this.download(response.data);
      }).catch(() => {});
    }
  }
};
</script>
